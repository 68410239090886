/** @format */

// * @format */
import React, { useState, useEffect } from "react";
import "@styles/carritoView.css";
import "@styles/checkout.css";
import { useTranslation, withTranslation } from "react-i18next";
import Checkout from "../checkout";
import Request from "../request";
import PayConfirmation from "../payConfirmation";
const { useParams } = require("react-router-dom");

const CarritoView = ({ carritoState }) => {
  // 0 = Carrito
  // 1 = Checkout
  // 2 = Pedido = PagoMedios
  const [page, setPage] = useState(0);
  const [t] = useTranslation("common");
  const [error, setError] = useState(false);
  const { pageConf, errorConf } = useParams();
  useEffect(() => {
    if (pageConf) {
      setPage(parseInt(pageConf));
    }
    if (errorConf) {
      setError(true);
    }
  }, [errorConf, pageConf]);
  const handlePagination = (page) => {
    setPage(page);
  };

  const saveInputs = () => {
    localStorage.setItem("inputs", JSON.stringify(inputs));
  };

  const carritoView = t("carritoView", { returnObjects: true });
  const breadCrumb = carritoView.breadCrumb;
  // carritoState.setCarrito = [
  //   { plan: "Silver Individual", price: 100, total: 100 },
  //   { plan: "Gold Individual", price: 100, total: 100 },
  //   { plan: "Diamond Individual", price: 100, total: 100 },
  // ];

  const [inputs, setInputs] = useState({
    aceptoTerminos_request1: true,

    // contactoPrincipal_request1: "",
    // correoElectronico_request1: "dpaulsoria@gmail.com",
    // correoElectronico_request2: "alvrobravo@gmail.com",
    // direccion_request1: "Alborada",
    // direccion_request2: "Quevedo",
    // fechaNacimiento_request1: "2023-01-01",
    // identificacion_request1: "0925730202",
    // identificacion_request2: "1250237565",
    // nombre_request1: "Paul Soria",
    // nombre_request2: "David Bravo",
    // sexo_request1: "Masculino",
    // telefonoCelular_request1: "0981089405",
    // telefonoConvencional_request1: "047847185",
    // telefonoConvencional_request2: "04125980",
    // tipoInformacion_request1: "cedula",
    // tipoPlan_request1: "individual",

    contactoPrincipal_request1: "",
    correoElectronico_request1: "",
    correoElectronico_request2: "",
    direccion_request1: "",
    direccion_request2: "",
    fechaNacimiento_request1: "",
    identificacion_request1: "",
    identificacion_request2: "",
    nombre_request1: "",
    nombre_request2: "",
    sexo_request1: "",
    telefonoCelular_request1: "",
    telefonoCelular_request2: "",
    telefonoConvencional_request1: "",
    tipoInformacion_request1: "cedula",
    tipoPlan_request1: "",
  });

  return (
    <div className="carritoView-section">
      <div id="breadCrumb" className="carritoView-breadCrumb">
        <div className="carritoView-breadCrumb-content">
          {breadCrumb.map((item, index) => (
            <React.Fragment key={`carritmb_2_${index}`}>
              <div
                className={`carritoView-breadCrumb-item`}
                href={item.link}
                // onClick={() => handlePagination(index)}
              >
                <div
                  key={`b7${index}`}
                  className={`carritoView-breadCrumb-bubble ${
                    page === index ? "carritoView-breadCrumb-active" : ""
                  }`}
                >
                  {item.content}
                </div>
                <div
                  key={`9d2${index}`}
                  className="carritoView-breadCrumb-span"
                >
                  {item.span}
                </div>
              </div>
              {item.content !== "3" && (
                <div
                  key={`5a${index}`}
                  className="carritoView-breadCrumb-icon"
                >{`|`}</div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="carritoView-container">
        {page === 0 && (
          <>
            <Request
              inputs={inputs}
              setInputs={setInputs}
              setPage={handlePagination}
            />
          </>
        )}
        {page === 1 && (
          <>
            <Checkout
              inputs={inputs}
              setInputs={setInputs}
              setPage={handlePagination}
              saveInputs={saveInputs}
            />
          </>
        )}
        {page === 2 && (
          <>
            <PayConfirmation inputs={inputs} errorCode={error} />
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation("common")(CarritoView);
