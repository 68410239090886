/** @format */

const URL_SERVER = process.env.REACT_APP_URL_SERVER;

const { saveAs } = require("file-saver");
const { utils, write } = require("xlsx");

// data es una lista de objetos json
export async function getExcelReport(data) {
  try {
    // Crear un libro de trabajo
    let wb = utils.book_new();

    // Convertir los datos a una hoja de trabajo
    let ws = utils.json_to_sheet(data);

    // Añadir la hoja de trabajo al libro de trabajo
    utils.book_append_sheet(wb, ws, "Sheet1");

    // Escribir el libro de trabajo en la memoria
    const wbout = write(wb, { type: "array" });

    // Usar FileSaver para descargar el archivo
    saveAs(new Blob([wbout]), "output.xlsx");
  } catch (error) {
    console.log("Error al descargar el archivo", error);
    return;
  }
}

export async function getTableReport(param, page) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/table?data=${param}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();
    return responseData.result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getBillingsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/billingsBySeller?email=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.success) return data.result;
    else return null;
  } catch (error) {
    console.log("Error al obtener las facturas por vendedor", error);
    return null;
  }
}

export async function getFormsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/formsBySeller?email=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.success) return data.result;
    else return null;
  } catch (error) {
    console.log("Error al obtener las transacciones por vendedor", error);
    return null;
  }
}
export async function getClientsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/clientsBySeller?email=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.success) {
      return data.result;
    } else return null;
  } catch (error) {
    console.log("Error al obtener los clientes por vendedor", error);
    return null;
  }
}
export async function getContractsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/contractsBySeller?email=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.success) return data.result;
    else return null;
  } catch (error) {
    console.log("Error al obtener los contratos por vendedor", error);
    return null;
  }
}

export async function getFinalClientsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/clientsBySeller?idVendedor=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los clientes finales por vendedor", error);
    return null;
  }
}

export async function getFinalClientsAll() {
  try {
    const response = await fetch(`${URL_SERVER}/reportes/clientsAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los clientes finales por vendedor", error);
    return null;
  }
}

export async function getFinalContractsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/contractsBySeller?idVendedor=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los contratos finales por vendedor", error);
    return null;
  }
}

export async function getFinalContractsAll() {
  try {
    const response = await fetch(`${URL_SERVER}/reportes/contractsAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los contratos finales por vendedor", error);
    return null;
  }
}

export async function getFinalBillingsBySeller(seller) {
  try {
    const response = await fetch(
      `${URL_SERVER}/reportes/billingsBySeller?idVendedor=${seller}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los contratos finales por vendedor", error);
    return null;
  }
}

export async function getFinalBillingsAll() {
  try {
    const response = await fetch(`${URL_SERVER}/reportes/billingsAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los contratos finales por vendedor", error);
    return null;
  }
}

export async function getFinalSellersAll() {
  try {
    const response = await fetch(`${URL_SERVER}/reportes/sellerAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener los contratos finales por vendedor", error);
    return null;
  }
}
