/** @format */

import React from "react";
import iconFb from "../../assets/images/facebook.svg";
import iconIg from "../../assets/images/instagram.svg";
import iconLink from "../../assets/images/linkedin.svg";
import iconBag from "../../assets/images/suitcase.svg";
import iconUser from "../../assets/images/user.svg";
import iconWs from "../../assets/images/whatsapp.svg";

import "@styles/bubbles.css";
import { withTranslation } from "react-i18next";

const BubbleComponent = () => {
  return (
    <div className="bubbles-container">
      <div className="bubbles-top">
       
        <a
          href="https://www.facebook.com/safewaylatam"
          className="bubbles-iconRS"
          target="_blank"
          rel="noreferrer"
        >
          <img src={iconFb} className="bubbles-icon" alt="Facebook" />
        </a>
        <a
          href="https://www.facebook.com/safewaylatam"
          className="bubbles-iconRS"
          target="_blank"
          rel="noreferrer"
        >
          <img src={iconIg} className="bubbles-icon" alt="Instagram" />
        </a>
        <a
          href="https://www.linkedin.com/safeway_latam?_l=es_ES"
          className="bubbles-iconRS"
          target="_blank"
          rel="noreferrer"
        >
          <img src={iconLink} className="bubbles-icon" alt="LinkedIn" />
        </a>
      </div>
      <div className="bubbles-bottom">
      
        <a
          id={`bubbles-carrito-bubble`}
          href="/carrito"
          className="bubbles-iconRS"
        >
          <img src={iconBag} className="bubbles-icon" alt="iconRS" />
        </a>
        <a
          id={`bubbles-account-bubble`}
          href="/mi-cuenta"
          className="bubbles-iconRS"
        >
          <img src={iconUser} className="bubbles-icon" alt="iconRS" />
        </a>
      </div>
     
      <a
        href="https://www.whatsapp.com"
        className="bubbles-iconRS whatsapp"
        target="_blank"
        rel="noreferrer"
      >
        <img src={iconWs} className="bubbles-icon" alt="WhatsApp" />
      </a>
    </div>
  );
};

export default withTranslation("common")(BubbleComponent);
