/** @format */

import React from "react";
import Routes from "./routes";
import Toast from "react-bootstrap/Toast";
import { useEffect, useState } from "react";
import { addObserver, removeObserver } from "@services/feedback";
import "@styles/toastStyle.css";
function App() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    addObserver(update);

    return () => {
      removeObserver(update);
    };
  }, []);

  function update(message, style) {
    setMessage(message);

    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 15000); // Ocultar después de 5 segundos
  }

  return (
    <>
      <Toast
        className="toast-content"
        show={show}
        onClose={() => setShow(false)}
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Safeway</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
      <Routes />
    </>
  );
}

export default App;
