/** @format */

import React from "react";
import "@styles/legal.css";
import { withTranslation, useTranslation } from "react-i18next";

const Legal = () => {
  const [t] = useTranslation("common");
  const text = t("legal", { returnObjects: true });
  return (
    <>
      <section className="legalContainer">
        <div className="legalSection">
          <div>
            <h1>{text.title}</h1>
            {text.content.map((item, index) => {
              return (
                <div key={index}>
                  <p>{item}</p>
                </div>
              );
            })}
          </div>
          <div>
            <p>{text.listContent.title}</p>
            <ol>
              {text.listContent.content.map((item, index) => {
                return (
                  <li key={index}>
                    <p>{item}</p>
                  </li>
                );
              })}
            </ol>
          </div>
          <div>
            <ol>
              {text.information.list.map((item, index) => {
                return (
                  <div key={index}>
                    <li>
                      <h3>{item.title}</h3>
                      {item.subtitle.bool && <p>{item.subtitle.content}</p>}
                      {item.content.map((item1, index1) => {
                        return <p key={index1}>{item1}</p>;
                      })}
                    </li>
                  </div>
                );
              })}
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};
export default withTranslation("common")(Legal);
