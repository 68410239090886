/** @format */

import React from "react";
import Details from "@components/details";
import { useParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "@styles/productsDetail.css";
const ProductDetail = () => {
  const [t] = useTranslation("common");
  const { planType } = useParams();

  const planName = planType.split("-")[0].toLowerCase();
  const planVariant = planType.split("-")[1].toLowerCase();

  const planList = ["silver", "gold", "diamond"];
  const variantList = ["individual", "grupal"];

  const showDetails =
    planList.includes(planName) && variantList.includes(planVariant);

  const data = t(`${planVariant}.${planName}`, { returnObjects: true });

  return (
    <>
      {showDetails ? (
        <Details data={data} />
      ) : (
        <div className="m-section">
          <div className="productsBanner-hero-img-section">
            <h2>Producto no encontrado</h2>
          </div>
          <div className="section-produ">
            <div className="productsBanner-notfound">
              <i className="bi bi-puzzle"></i>
            </div>
            <div className="productsBanner-notfound-text">
              Este producto no existe, por favor verifica la URL
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation("common")(ProductDetail);
