/** @format */

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "@styles/modal.css";

const CustomModal = ({ classPrefix, modal }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <p className={`${classPrefix}-accede`} onClick={() => handleShow()}>
        {modal.action}
      </p>
      <Modal show={show} onHide={handleClose} scrollable centered size="lg">
        <Modal.Body>
          <div className="close" onClick={handleClose}>
            <i className="bi bi-x-lg"></i>
          </div>
          <div className="text-center pt-3 text-header">{modal.title}</div>
          {modal.subtitle.bool && (
            <div className="text-subtitle">{modal.subtitle.content}</div>
          )}
          <ul className="text-start pt-2">
            {modal.data.map((item, index) => {
              return <li key={`moreInfo_${index}`}>{item}</li>;
            })}
          </ul>
          {modal.footer.bool && (
            <div className="text-footer">{modal.footer.content}</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CustomModal;
