/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
  getContracts,
  updateDebt,
  getContractsBySeller,
} from "@services/contracts";
import { verifySeller } from "@services/sellers";
import {
  getTableReport,
  getExcelReport,
  getFinalClientsBySeller,
  getFinalClientsAll,
} from "@services/reportes";
import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";
import "simple-datatables/dist/style.css";
import { DataTable } from "simple-datatables";
import "@styles/filterTable.css";
import { getClientsBySeller, getAllClients } from "@services/clients";
import { reRegisterCard, deleteCard } from "@services/payments";

import "@styles/dashCustomer.css";
import "@styles/master.css";
import "@styles/seller.css";
import ConfirmationModal from "../modals/confirmationModal";
const DasCustomers = (props) => {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [ward] = useState(Cookies.get("ward"));
  const [page] = useState(1);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [tempContent1, setTempContent1] = useState({});
  const [tempContent2, setTempContent2] = useState({});
  const myTableRef = useRef(null);
  let dataTable;

  const dataTableOptions = {
    searchable: true,
    fixedHeight: true,
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
    },
  };

  async function fetchService() {
    let response = [];
    const token = Cookies.get("token");
    const seller = await verifySeller(token);
    if (ward === "1") {
      response = await getFinalClientsBySeller(seller.result.email);
    } else if (ward === "0") {
      response = await getFinalClientsAll();
    }
    return response || [];
  }

  const handleDownload = async () => {
    getExcelReport(data);
  };
  async function registerClientCard() {
    const response = await reRegisterCard(
      tempContent2.ci,
      "05",
      tempContent2.name,
      tempContent2.email,
      tempContent2.phone,
      "Individual"
    );
    if (response.success) {
      window.location.href = response.url;
    }
  }

  async function deleteClientCard() {
    const response = await deleteCard(tempContent1);

    if (response.success) {
      window.location.reload();
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchService();
      setData(data);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (dataLoaded && myTableRef.current) {
      if (dataTable) {
        dataTable.destroy();
      }
      dataTable = new DataTable(myTableRef.current, dataTableOptions);
      props.domMethod();
    }
  }, [dataLoaded]);
  async function handleshow1(email) {
    setShow1(true);
    setTempContent1(email);
  }
  async function handleshow2(client) {
    setShow2(true);
    setTempContent2(client);
  }
  return (
    <>
      <ConfirmationModal
        show={show1}
        setShow={setShow1}
        text={"¿Está seguro que desea eliminar la tarjeta?"}
        method={deleteClientCard}
      />
      <ConfirmationModal
        show={show2}
        setShow={setShow2}
        text={"¿Está seguro que desea registrar una nueva tarjeta?"}
        method={registerClientCard}
      />
      <section className="dashCust-container">
        <section className="dasCusto-card rounded">
          <div className="seller-content-header">
            <div className="seller-title">
              <span>Clientes</span>
            </div>
            <div className="pdf-down" onClick={handleDownload}>
              <i className="bi bi-filetype-xlsx"></i>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr>
                  {ward === "1" && (
                    <>
                      <th>Nombres y apellidos</th>
                      <th>Identificación</th>
                      <th>Correo</th>
                      <th>Direccion</th>
                      <th>Celular</th>
                      <th>Contratos activos</th>
                      <th>Fecha de registro</th>
                    </>
                  )}
                  {ward === "0" && (
                    <>
                      <th>Nombres y apellidos</th>
                      <th>Identificación</th>
                      <th>Correo</th>
                      <th>Direccion</th>
                      <th>Celular</th>
                      <th>Contratos activos</th>
                      <th>Fecha de registro</th>
                      <th>Registrar tarjeta</th>
                      <th>Eliminar tarjeta</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  ward === "1" &&
                  data.map((item, index) => (
                    <tr key={`a${index}`}>
                      <td key={`b${index}`}>{item.name}</td>
                      <td key={`c${index}`}>{item.ci}</td>
                      <td key={`d${index}`}>{item.email}</td>
                      <td key={`e${index}`}>{item.address}</td>
                      <td key={`f${index}`}>{item.cellphone}</td>
                      <td key={`g${index}`}>{item.activeContracts}</td>
                      <td key={`h${index}`}>{`${
                        item.createdAt.split("T")[0]
                      }`}</td>
                    </tr>
                  ))}
                {data.length > 0 &&
                  ward === "0" &&
                  data.map((item, index) => (
                    <tr key={`i${index}`}>
                      <td key={`j${index}`}>{item.name}</td>
                      <td key={`k${index}`}>{item.ci}</td>
                      <td key={`l${index}`}>{item.email}</td>
                      <td key={`m${index}`}>{item.address}</td>
                      <td key={`n${index}`}>{item.cellphone}</td>
                      <td key={`o${index}`}>{item.activeContracts}</td>
                      <td key={`p${index}`}>{`${
                        item.createdAt.split("T")[0]
                      }`}</td>
                      <td className="text-center">
                        <button
                          className="button2"
                          disabled={item.card_token !== null ? true : false}
                          onClick={() => handleshow2(item)}
                        >
                          Registrar
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="button1"
                          disabled={item.card_token === null ? true : false}
                          onClick={() => handleshow1(item.email)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </>
  );
};

export default DasCustomers;
