/** @format */

import React from "react";

import { getServiceProducts, setServiceProduct } from "../services/carrito";

import hotelEstrellasSVG from "@images/icons/hotel-estrellas.svg";
import automovilIntermedioSVG from "@images/icons/automovil-intermedio.svg";
import chipInternacionalSVG from "@images/icons/chip-internacional.svg";
import diasEnfermedadSVG from "@images/icons/dias-enfermedad.svg";
import enfermedadSafewaySVG from "@images/icons/enfermedad-safeway.svg";
import pasajeAereoSVG from "@images/icons/pasaje-aereo.svg";
import viajePremiumSVG from "@images/icons/viaje-premium.svg";
import { getBaseProduct, getAnexoProduct } from "@services/productos";
import Decimal from "decimal.js";

import CustomModal from "./modals/modal";
import ModalInfo from "@components/modals/modalInfo";
import { useNavigate } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

import { Button, Modal, FloatingLabel, Form, Dropdown } from "react-bootstrap";

const Details = ({ data }) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [product, setProduct] = React.useState({});
  const [anexoenv, setAnexoenv] = React.useState({});
  const [anexomv, setAnexomv] = React.useState({});
  const [isAMChecked, setIsAMChecked] = React.useState(false);
  const [isEAChecked, setIsEAChecked] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const handleShow = () => {
    localStorage.setItem("state", JSON.stringify(data));
    navigate("/carrito#", { state: data, replace: true });
    eliminarSiExiste();
  };
  const eliminarSiExiste = () => {
    if (localStorage.getItem("formData") !== null) {
      localStorage.removeItem("formData");
    }
  };
  const handleConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleAMCheckbox = () => {
    setIsAMChecked(!isAMChecked);
    if (!isAMChecked) {
      setServiceProduct(true, "anexomv");
    } else {
      setServiceProduct(false, "anexomv");
    }
  };
  const handleEACheckbox = () => {
    setIsEAChecked(!isEAChecked);
    if (!isEAChecked) {
      setServiceProduct(true, "anexoenv");
    } else {
      setServiceProduct(false, "anexoenv");
    }
  };

  function getCheckboxType(name) {
    switch (name) {
      case "anexo-m":
        return [isAMChecked, handleAMCheckbox];
      case "anexo-ea":
        return [isEAChecked, handleEACheckbox];
      default:
        break;
    }
  }
  function productMoreIva(value) {
    const valueTmp = new Decimal(value);
    const tax = new Decimal(1.12);
    const valueMoreIva = valueTmp.times(tax);
    return valueMoreIva.toFixed(2);
  }
  function getSVG(name) {
    switch (name) {
      case "hotel-estrellas":
        return hotelEstrellasSVG;
      case "automovil-intermedio":
        return automovilIntermedioSVG;
      case "chip-internacional":
        return chipInternacionalSVG;
      case "dias-enfermedad":
        return diasEnfermedadSVG;
      case "enfermedad-safeway":
        return enfermedadSafewaySVG;
      case "pasaje-aereo":
        return pasajeAereoSVG;
      case "viaje-premium":
        return viajePremiumSVG;
      default:
        break;
    }
  }

  React.useEffect(() => {
    const getPrices = async () => {
      try {
        const rest = await getServiceProducts();
        setIsAMChecked(rest["anexomv"]);
        setIsEAChecked(rest["anexoenv"]);

        const response = await getBaseProduct(data.db);
        if (response) {
          setProduct(response);
          const anexoenv = await getAnexoProduct(
            response.nombre,
            response.variante,
            "ENF/ACC"
          );
          const anexomv = await getAnexoProduct(
            response.nombre,
            response.variante,
            "MULTIVIAJES"
          );

          setAnexoenv(anexoenv);
          setAnexomv(anexomv);
        }
      } catch (error) {}
    };
    getPrices();
  }, [data.db]);

  const tmp = {
    price: product.precio,
    anexos_m: anexomv.precio,
    anexos_ea: anexoenv.precio,
  };

  const products_price = {
    price: productMoreIva(parseFloat(tmp.price)),
    anexos_m: productMoreIva(parseFloat(tmp.anexos_m)),
    anexos_ea: productMoreIva(parseFloat(tmp.anexos_ea)),
  };

 
  return (
    <div className="m-section">
    
      <div className="productsBanner-hero-img-section">
        <h2>{data.title}</h2>
      </div>
      <div className="m-header m-bottom-border">
        <div className="m-subtitle">
          <h3>{data.subtitle.title}</h3>
        
          <p>{data.subtitle.description}</p>
        </div>
      </div>
      <div className="m-content">
        <div className="m-benefits m-bottom-border">
          <div className="m-header m-bottom-border">
            <h2>{data.benefits.title}</h2>
            {data.benefits.adds.bool && (
              <span>{data.benefits.adds.content}</span>
            )}
          </div>
          <div className="m-benefits-list">
            {data.benefits.list.map((item, index) => {
              return (
                <div
                  key={`${item.content}-m-benefits-itm_${index}`}
                  className="m-benefits-item"
                >
                  <div className="m-benefits-svg">
                    <img src={getSVG(item.path)} alt="SVG icon" />
                  </div>
                  <span>{item.content}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="m-price">
          <div
            className={`m-price-header productsBanner-background-plan-${
              data.price.title.split(" ")[1]
            }`}
          >
            <h2>{data.price.title}</h2>
          </div>
          <div className="m-price-content">
            <div className="m-price-value">
              <div className="m-price-number">
                <span>{data.price.value.span}</span>
                <p>$ {products_price.price}</p>
              </div>
              <span>{data.price.value.method}</span>
            </div>
            <div className="m-anexos-header">
              <h3>{data.price.action}</h3>
            </div>
            <div className="m-price-anexos">
              <div className="m-anexos-list">
                {data.price.anexos.map((item, index) => {
                  let type = getCheckboxType(item.id);
                  return (
                    <div
                      id={item.id}
                      key={`${item.id}-${item.title}-m-anexos-itm_${index}`}
                      className="m-anexos-item"
                    >
                      <div className="m-anexos-svg">
                        <img
                          src={getSVG(item.path)}
                          alt={`Icono representando el anexo ${item.title}`}
                        />
                      </div>
                      <div className="m-anexos-content">
                        <label className="m-anexos-checkbox">
                          <input
                            type="checkbox"
                            checked={type[0]}
                            onChange={type[1]}
                          />
                          <p> {`${item.title}`}</p>
                          <span>
                            (+ $
                            {productMoreIva(
                              parseFloat(item.value.valueInteger)
                            )}
                            )
                          </span>
                        </label>

                        <div className="m-anexos-info">
                          <p>{item.description}</p>
                        </div>
                        <div className="m-anexo-action">
                          <ModalInfo
                            classPrefix={`m`}
                            code={
                              item.id.split("-")[1] === "m"
                                ? "multiviajes"
                                : "enfermedades"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="m-price-detail">
              <div className="m-price-detail-container">
                <div className="m-price-d-price  m-price-row">
                  <p>{data.price.details.price}</p>
                  <span>$ {products_price.price}</span>
                </div>
                <div className="m-price-d-opt m-price-row">
                  <p>{data.price.details.options}</p>
                  <span>
                    $
                    {(
                      parseFloat(isAMChecked ? products_price.anexos_m : 0) +
                      parseFloat(isEAChecked ? products_price.anexos_ea : 0)
                    ).toFixed(2)}
                  </span>
                </div>
                <div className="m-price-d-total m-price-row">
                  <p>{data.price.details.total}</p>
                  <span>
                    {(
                      parseFloat(products_price.price) +
                      parseFloat(isAMChecked ? products_price.anexos_m : 0) +
                      parseFloat(isEAChecked ? products_price.anexos_ea : 0)
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className="m-price-d-buy" onClick={handleShow}>
              <div>{`Emitir aplicación`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="productsBanner-modals-section">
        <CustomModal
          classPrefix={`productsBanner`}
          modal={t("modals.benefits", { returnObjects: true })}
        />
        <CustomModal
          classPrefix={`productsBanner`}
          modal={t("modals.conditions", { returnObjects: true })}
        />
        <CustomModal
          classPrefix={`productsBanner`}
          modal={t("modals.requirements", { returnObjects: true })}
        />
      </div>
    </div>
  );
};

export default withTranslation("common")(Details);
