const URL_SERVER = process.env.REACT_APP_URL_SERVER;

export async function registerCardAndPay(
  id,
  id_type,
  name,
  email,
  phone,
  address,
  type
) {
  const response = await fetch(`${URL_SERVER}/payments/registerCard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      id_type,
      name,
      email,
      phone,
      address,
      type,
    }),
  });
  const data = await response.json();
  return data;
}

export async function reRegisterCard(id, id_type, name, email, phone, type) {
  const response = await fetch(`${URL_SERVER}/payments/re-registerCard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      id_type,
      name,
      email,
      phone,
      type,
    }),
  });
  const data = await response.json();
  return data;
}

export async function getPaymentRequest(email, selectedOption, message, cant = 1) {
  try {
    const response = await fetch(`${URL_SERVER}/payments/paymentRequest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        selectedOption,
        message,
        cant
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error al obtener el payment request", error);
    return;
  }
}

export async function payAmount(
  id,
  card_token,
  amount,
  amount_with_tax,
  amount_without_tax,
  tax_value
) {
  const response = await fetch(`${URL_SERVER}/payments/payAmount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      card_token,
      amount,
      amount_with_tax,
      amount_without_tax,
      tax_value,
    }),
  });
  const data = await response.json();
  return data;
}

export async function deleteCard(email) {
  const response = await fetch(`${URL_SERVER}/payments/deleteCard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
  const data = await response.json();
  return data;
}
