/** @format */

import React, { useEffect, useState } from "react";
import "@styles/request.css";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import { getServiceProducts } from "../services/carrito";
import { useTranslation, withTranslation } from "react-i18next";
import { setServiceProduct } from "@services/carrito";
import { useNavigate } from "react-router-dom";
import { notify } from "@services/feedback";

const Formulario = ({ inputs, setInputs, setPage }) => {
  const navigate = useNavigate();
  const [t] = useTranslation("common");
  const [beneficiariosCount, setBeneficiariosCount] = useState(() => {
    const countFromStorage = localStorage.getItem("cantBnf");
    return countFromStorage !== null ? Number(countFromStorage) : 0;
  });

  const manejarCambioDinamic = (event) => {
    const { name, value } = event.target;
    guardarCampoFormulario(name, value);
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const manejarBlurDinamic = (name, index) => {
    const regexNombre = /^[\p{L}\p{N}\sáéíóúÁÉÍÓÚüÜ]{1,40}$/u;
    const regexCedula = /^\d{10}$/;
    const regexFechaNacimiento = /^\d{4}-\d{2}-\d{2}$/;
    const regexSexo = /^(Masculino|Femenino)$/i;

    if (name === "Nombre_Bnf_SEC_REQ_1.") {
      let test = inputs[`Nombre_Bnf_SEC_REQ_1.${index}`];

      if (regexNombre.test(test) && test !== "") {
        agreeValidation(name, index);
      } else {
        disagreeValidation(name, index);
      }
    }

    if (name === "Cedula_Bnf_SEC_REQ_1.") {
      if (regexCedula.test(inputs[`Cedula_Bnf_SEC_REQ_1.${index}`])) {
        if (
          isConteined(
            `Cedula_Bnf_SEC_REQ_1.${index}`,
            inputs[`Cedula_Bnf_SEC_REQ_1.${index}`]
          )
        ) {
          disagreeValidation(name, index);
          
        } else {
          agreeValidation(name, index);
         
        }
      } else {
        disagreeValidation(name, index);
       
      }
    }

    if (name === "fechaNAC.") {
      if (regexFechaNacimiento.test(inputs[`fechaNAC.${index}`])) {
        agreeValidation(name, index);
      } else {
        disagreeValidation(name, index);
      }
    }

    if (name === "Sexo_SEC_1.") {
      if (regexSexo.test(inputs[`Sexo_SEC_1.${index}`])) {
        agreeValidation(name, index);
      } else {
        disagreeValidation(name, index);
      }
    }
  };

  const isConteined = (name, value) => {
    let flag = false;
    Object.keys(inputs).forEach((key) => {
      if (key !== name && inputs[key] === value) {
        flag = true;
        notify("La cédula ingresada ya existe.");
      }
    });
    return flag;
  };

  const agreeValidation = (name, index) => {
    setValium((prevState) => ({
      ...prevState,
      [`${name}${index}`]: true,
    }));
    setTouched((prevState) => ({
      ...prevState,
      [`${name}${index}`]: true,
    }));
  };
  const disagreeValidation = (name, index) => {
    setValium((prevState) => ({
      ...prevState,
      [`${name}${index}`]: false,
    }));
    setTouched((prevState) => ({
      ...prevState,
      [`${name}${index}`]: true,
    }));
  };
  const handleAddBenefitprev = (index) => {
    handleAddBenefit(index);
    setBeneficiariosCount(beneficiariosCount + 1);
  };
  const handleAddBenefit = (index) => {
    if (beneficiariosCount < 9) {
      localStorage.setItem("cantBnf", beneficiariosCount);

      setInputs((prevState) => {
        const newInputs = {
          ...prevState,
          [`Nombre_Bnf_SEC_REQ_1.${index}`]:
            prevState[`Nombre_Bnf_SEC_REQ_1.${index}`] || "",
          [`Cedula_Bnf_SEC_REQ_1.${index}`]:
            prevState[`Cedula_Bnf_SEC_REQ_1.${index}`] || "",
          [`fechaNAC.${index}`]: prevState[`fechaNAC.${index}`] || "",
          [`Sexo_SEC_1.${index}`]: prevState[`Sexo_SEC_1.${index}`] || "",
        };
        setValium((prevValium) => ({
          ...prevValium,
          [`Nombre_Bnf_SEC_REQ_1.${index}`]:
            !!newInputs[`Nombre_Bnf_SEC_REQ_1.${index}`],
          [`Cedula_Bnf_SEC_REQ_1.${index}`]:
            !!newInputs[`Cedula_Bnf_SEC_REQ_1.${index}`],
          [`fechaNAC.${index}`]: !!newInputs[`fechaNAC.${index}`],
          [`Sexo_SEC_1.${index}`]: !!newInputs[`Sexo_SEC_1.${index}`],
        }));
        setTouched((prevTouched) => ({
          ...prevTouched,
          [`Nombre_Bnf_SEC_REQ_1.${index}`]:
            !!newInputs[`Nombre_Bnf_SEC_REQ_1.${index}`],
          [`Cedula_Bnf_SEC_REQ_1.${index}`]:
            !!newInputs[`Cedula_Bnf_SEC_REQ_1.${index}`],
          [`fechaNAC.${index}`]: !!newInputs[`fechaNAC.${index}`],
          [`Sexo_SEC_1.${index}`]: !!newInputs[`Sexo_SEC_1.${index}`],
        }));
        return newInputs;
      });
    }
  };

  const handleSubsprerBenefit = (indexToRemove) => {
    handleSubBenefit(indexToRemove);
    setBeneficiariosCount(beneficiariosCount - 1);
  };

  const handleSubBenefit = (indexToRemove) => {
    if (beneficiariosCount > 0) {
      const numIterations = beneficiariosCount - indexToRemove;
      for (let i = 0; i < numIterations - 1; i++) {
        reorden(indexToRemove + i, setInputs);
        reorden(indexToRemove + i, setValium);
        reorden(indexToRemove + i, setTouched);
      }
    }
    deleteLast(beneficiariosCount - 1, setInputs);
    deleteLast(beneficiariosCount - 1, setValium);
    deleteLast(beneficiariosCount - 1, setTouched);
  };

  const reorden = (index, method) => {
    method((prevState) => {
      const newInputs = {
        ...prevState,
        [`Nombre_Bnf_SEC_REQ_1.${index}`]:
          prevState[`Nombre_Bnf_SEC_REQ_1.${index + 1}`],
        [`Cedula_Bnf_SEC_REQ_1.${index}`]:
          prevState[`Cedula_Bnf_SEC_REQ_1.${index + 1}`],
        [`fechaNAC.${index}`]: prevState[`fechaNAC.${index + 1}`],
        [`Sexo_SEC_1.${index}`]: prevState[`Sexo_SEC_1.${index + 1}`],
      };
      return newInputs;
    });
  };
  const deleteLast = (index, method) => {
    method((prevState) => {
      const newInputs = { ...prevState };
      delete newInputs[`Nombre_Bnf_SEC_REQ_1.${index}`];
      delete newInputs[`Cedula_Bnf_SEC_REQ_1.${index}`];
      delete newInputs[`fechaNAC.${index}`];
      delete newInputs[`Sexo_SEC_1.${index}`];
      return newInputs;
    });
  };
  useEffect(() => {
    modificarValorCount(beneficiariosCount);
  }, [beneficiariosCount]);
  const modificarValorCount = (nuevoValor) => {
    // Comprobar si "count" existe en el localStorage
    if (localStorage.getItem("cantBnf") !== null) {
      // Si existe, modificarlo al nuevo valor
      localStorage.setItem("cantBnf", nuevoValor.toString());
    }
  };
  useEffect(() => {
    let previosForm = obtenerFormulario();
    if (previosForm) {
      Object.keys(previosForm).forEach((key) => {
        setInputs((prevState) => ({
          ...prevState,
          [key]: previosForm[key],
        }));
        setValium((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        setTouched((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      });
    }
    if (JSON.parse(localStorage.getItem("products")).type === "grupal") {
      let count = localStorage.getItem("cantBnf");
      if (count === null) {
        count = 1;
        localStorage.setItem("cantBnf", count.toString());
      } else {
        count = Number(count);
      }
      setBeneficiariosCount(count);
      addBeneficiarios(count - 1);
    }

    const getProducts = async () => {
      const response = await getServiceProducts();
      setInputs((prevInputs) => ({
        ...prevInputs,
        tipoPlan_request1: response.type.toLowerCase(),
      }));
    };
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInputs]);
  const guardarCampoFormulario = (nombreCampo, valorCampo) => {
    let dataExp = JSON.parse(localStorage.getItem("formData")) || {};
    let formData = dataExp.data || {};
    formData[nombreCampo] = valorCampo;
    // Guardar el tiempo de expiración junto con los datos del formulario
    const tiempoDeExpiracion = new Date().getTime() + 2 * 5 * 60 * 1000; // 5 minutos
    localStorage.setItem(
      "formData",
      JSON.stringify({ data: formData, tiempoDeExpiracion })
    );
  };

  const obtenerFormulario = () => {
    let dataExp = JSON.parse(localStorage.getItem("formData"));
    let formData = dataExp?.data || {};

    if (Object.keys(formData).length === 0) return null;
    if (new Date().getTime() > dataExp.tiempoDeExpiracion) {
      localStorage.removeItem("formData");
      return null;
    } else {
      return formData;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const camposVerificar = Object.entries(valido).reduce(
      (obj, [campo, valor]) => {
        if (campo !== "contactoPrincipal_request1") {
          obj[campo] = valor;
        }
        return obj;
      },
      {}
    );
    const todosLosCamposValidos = Object.values(camposVerificar).every(
      (valor) => valor === true
    );

    if (todosLosCamposValidos) {
      localStorage.setItem("cantBnf", beneficiariosCount);
      setPage(1);
    } else {
      notify("Por favor, complete los campos obligatorios.");
    }
  };

  const [valido, setValium] = useState({
    aceptoTerminos_request1: false,
    contactoPrincipal_request1: false,
    correoElectronico_request1: false,
    direccion_request1: false,
    fechaNacimiento_request1: false,
    identificacion_request1: false,
    nombre_request1: false,
    sexo_request1: false,
    telefonoCelular_request1: false,
    telefonoConvencional_request1: false,
  });
  const [touched, setTouched] = useState({
    aceptoTerminos_request1: false,
    contactoPrincipal_request1: false,
    correoElectronico_request1: false,
    direccion_request1: false,
    fechaNacimiento_request1: false,
    identificacion_request1: false,
    nombre_request1: false,
    sexo_request1: false,
    telefonoCelular_request1: false,
    telefonoConvencional_request1: false,
  });
  const regex = {
    email_request1: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    password_request1: /^[a-zA-Z0-9]{6,}$/,
    tipoPlan_request1: /^[a-zA-Z0-9\s]{1,50}$/,
    tipoInformacion_request1: /^[a-zA-Z0-9\s]{1,50}$/,
    identificacion_request1: /^\d{10}$|^\d{13}$/,
    nombre_request1: /^[a-zA-Z\s]{1,100}$/,
    contactoPrincipal_request1: /^[a-zA-Z\s]{1,100}$/,
    telefonoConvencional_request1: /^\d{9}$/,
    telefonoCelular_request1: /^\d{10}$/,
    correoElectronico_request1: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    direccion_request1: /^.{1,100}$/,
    tipoTarjeta_request1: /^Visa$|^MasterCard$|^American$/,
    numeroTarjeta_request1: /^.*$/,
    nombreTarjeta_request1: /^[a-zA-Z\s]{1,100}$/,
    fechaExpiracion_request1: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
    aceptoTerminos_request1: /^true$/,
    // otros valores

    correoElectronico_request2: /^[^\s@]+@[^\s@]+.[^\s@]+$/,
    direccion_request2: /^[a-zA-Z0-9\s,]{1,100}$/,
    identificacion_request2: /^\w+$/,
    nombre_request2: /^[a-zA-Z\s]{1,100}$/,
    sexo_request1: /^(Masculino|Femenino)$/,

    telefonoConvencional_request2: /^\d{9}$/,
  };
  function validarFechaNacimiento(fechaNacimiento) {
    var fechaNac = new Date(fechaNacimiento);
    var fechaActual = new Date();
    var diferenciaEnAños = fechaActual.getFullYear() - fechaNac.getFullYear();
    if (
      fechaActual.getMonth() < fechaNac.getMonth() ||
      (fechaActual.getMonth() === fechaNac.getMonth() &&
        fechaActual.getDate() < fechaNac.getDate())
    ) {
      diferenciaEnAños--;
    }
    if (diferenciaEnAños >= 18) {
      return true;
    } else {
      return false;
    }
  }

  const validarInput = (nombreCampo, valor) => {
    if (nombreCampo === "fechaNacimiento_request1") {
      // debo de setear en setINputs el campo fechaNacimiento_request1
      setValium((prevInputs) => ({
        ...prevInputs,
        fechaNacimiento_request1: validarFechaNacimiento(valor),
      }));
    } else if (regex[nombreCampo].test(valor)) {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: true }));
    } else {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: false }));
    }
  };
  const deleteBeneficiarios = (beneficiariosCount) => {
    while (beneficiariosCount > 0) {
      handleSubBenefit(beneficiariosCount);
      beneficiariosCount--;
    }
    setBeneficiariosCount(0);
    localStorage.setItem("cantBnf", 0);
  };
  const addBeneficiarios = (beneficiariosToAdd) => {
    while (beneficiariosToAdd >= 0) {
      handleAddBenefit(beneficiariosToAdd);
      beneficiariosToAdd--;
    }
  };
  const manejarCambio = (event) => {
    const { name, value } = event.target;
    guardarCampoFormulario(name, value);
    if (name === "group2_request1") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        tipoInformacion_request1: value,
      }));
      setValium((prevValido) => ({
        ...prevValido,
        tipoInformacion_request1: true,
      }));
    } else if (name === "sexo_request1") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        sexo_request1: value,
      }));
      setValium((prevValido) => ({
        ...prevValido,
        sexo_request1: true,
      }));
    } else if (name === "aceptoTerminos_request1") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        aceptoTerminos_request1: event.target.checked,
      }));
      setValium((prevValido) => ({
        ...prevValido,
        aceptoTerminos_request1: event.target.checked,
      }));
    } else if (name === "group1_request1") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        tipoPlan_request1: value,
      }));
      if (beneficiariosCount > 0 && value === "individual") {
        deleteBeneficiarios(beneficiariosCount);
        localStorage.setItem("cantBnf", 0);
      }

      if (value === "individual") {
        setServiceProduct("individual", "type");
      }
      if (value === "grupal") {
        setServiceProduct("grupal", "type");
        setBeneficiariosCount(1);
        addBeneficiarios(0);
      }
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }

    if (name === "identificacion_request1") {
      formatID(value, inputs.tipoInformacion_request1);
    }

    if (name === "numeroTarjeta_request1") {
      formatCardNumber(value);
    } else if (name === "fechaExpiracion_request1") {
      formatExpirationDate(value);
    }

    if (name === "nombre_request1") {
      formatNombre(value, name);
    }

    if (name === "contactoPrincipal_request1") {
      formatNombrePrincipal(value);
    }
    if (name === "nombreTarjeta_request1") {
      formatNombreTarjeta(value);
    }

    if (name === "telefonoCelular_request1") {
      formatTelefonoCelular(value);
    }
    if (name === "telefonoConvencional_request1") {
      formatTelefonoConvencional(value);
    }
  };
  const formatTelefonoConvencional = (input) => {
    const digitsOnly = input.replace(/\D/g, "");

    const limitedTelefonoCelular = digitsOnly.slice(0, 9);

    const formattedTelefonoCelular = limitedTelefonoCelular.startsWith("04")
      ? limitedTelefonoCelular
      : "04" + limitedTelefonoCelular;

    setInputs((prevInputs) => ({
      ...prevInputs,
      telefonoConvencional_request1: formattedTelefonoCelular,
    }));
  };

  const formatTelefonoCelular = (input) => {
    const digitsOnly = input.replace(/\D/g, "");

    const limitedTelefonoCelular = digitsOnly.slice(0, 10);

    const formattedTelefonoCelular = limitedTelefonoCelular.startsWith("0")
      ? limitedTelefonoCelular
      : "0" + limitedTelefonoCelular;

    setInputs((prevInputs) => ({
      ...prevInputs,
      telefonoCelular_request1: formattedTelefonoCelular,
    }));
  };

  const formatID = (input, type) => {
    const digitsOnly = input.replace(/\D/g, "");

    const n = type === "ruc" ? 13 : 10;
    const limitedCedula = digitsOnly.slice(0, n);

    setInputs((prevInputs) => ({
      ...prevInputs,
      identificacion_request1: limitedCedula,
    }));
  };

  const formatNombre = (input) => {
    const formattedNombre = input.replace(/[^a-zA-Z\s]/g, "");

    setInputs((prevInputs) => ({
      ...prevInputs,
      nombre_request1: formattedNombre,
    }));
  };

  const formatNombrePrincipal = (input) => {
    const formattedNombre = input.replace(/[^a-zA-Z\s]/g, "");

    setInputs((prevInputs) => ({
      ...prevInputs,
      contactoPrincipal_request1: formattedNombre,
    }));
  };

  const formatNombreTarjeta = (input) => {
    const formattedNombre = input.replace(/[^a-zA-Z\s]/g, "");

    setInputs((prevInputs) => ({
      ...prevInputs,
      nombreTarjeta_request1: formattedNombre,
    }));
  };

  const formatCardNumber = (input) => {
    const cardNumber = input.replace(/[\s-]/g, "");

    const limitedCardNumber = cardNumber.slice(0, 16);

    const sanitizedCardNumber = limitedCardNumber.replace(/[^0-9]/g, "");

    let formattedNumber = "";
    for (let i = 0; i < sanitizedCardNumber.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedNumber += " ";
      }
      formattedNumber += sanitizedCardNumber[i];
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      numeroTarjeta_request1: formattedNumber,
    }));
  };

  const formatExpirationDate = (input) => {
    const digitsOnly = input.replace(/\D/g, "");

    const month = digitsOnly.slice(0, 2);

    const year = digitsOnly.slice(2, 4);

    let formattedDate = "";

    if (month.length > 0) {
      formattedDate += month;

      if (month.length === 2 && year.length > 0) {
        formattedDate += "/" + year;
      }
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      fechaExpiracion_request1: formattedDate,
    }));
  };

  const manejarBlur = (event) => {
    const { name, value } = event.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validarInput(name, value);
  };

  const text = t(`request`, { returnObjects: true });

  return (
    <div className="formulario-content">
      <form onSubmit={handleSubmit} className="formulario">
        <div className="formulario-titulo">
          <h2>{text.title}</h2>
        </div>
        <h3 className="formulario-etiqueta">{text.subtitle}</h3>
        <div key={`inline-radio`} className="mb-3">
          <Form.Check
            label="Individual"
            name="group1_request1"
            type="radio"
            value="individual"
            checked={inputs.tipoPlan_request1 === "individual"}
            id={`inline-radio-1`}
            onChange={manejarCambio}
          />
          <Form.Check
            inline
            label="Grupal"
            name="group1_request1"
            type="radio"
            value="grupal"
            id={`inline-radio-2`}
            checked={inputs.tipoPlan_request1 === "grupal"}
            onChange={manejarCambio}
          />
        </div>
        <hr className="formulario-separador" />
        <h3 className="formulario-etiqueta">Datos de Beneficiario Principal</h3>
        <div className="formulario-grid">
          <div className="request-formulario-seccion">
            <p className="formulario-etiqueta request-block">
              {text.label.subtitle}
            </p>
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label="Cédula de Identidad"
                name="group2_request1"
                type="radio"
                value="cedula"
                checked={inputs.tipoInformacion_request1 === "cedula"}
                id="inline-radio-2-1"
                onChange={manejarCambio}
                key={`inline-radio_2_1`}
              />
              <Form.Check
                inline
                label="RUC"
                name="group2_request1"
                type="radio"
                value="ruc"
                checked={inputs.tipoInformacion_request1 === "ruc"}
                id="inline-radio-2-1"
                onChange={manejarCambio}
                key={`inline-radio_2_2`}
              />
            </div>
          </div>
          <div className="request-formulario-seccion">
            <p className="formulario-etiqueta request-block">Sexo</p>
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label="Hombre"
                name="sexo_request1"
                type="radio"
                value="Masculino"
                checked={inputs.sexo_request1 === "Masculino"}
                id="inline-radio-2-1"
                key={`inline-radio_2_1`}
                onChange={manejarCambio}
              />
              <Form.Check
                inline
                label="Mujer"
                name="sexo_request1"
                type="radio"
                value="Femenino"
                id="inline-radio-2-1"
                checked={inputs.sexo_request1 === "Femenino"}
                key={`inline-radio_2_2`}
                onChange={manejarCambio}
              />
            </div>
          </div>
          {inputs.tipoInformacion_request1 === "ruc" && (
            <FloatingLabel label="Contacto Principal*">
              <Form.Control
                type="text"
                id="name_registermb"
                name="contactoPrincipal_request1"
                placeholder="Contacto Principal"
                value={inputs.contactoPrincipal_request1}
                onChange={manejarCambio}
                onBlur={manejarBlur}
                isValid={
                  valido.contactoPrincipal_request1 &&
                  touched.contactoPrincipal_request1
                }
                isInvalid={
                  !valido.contactoPrincipal_request1 &&
                  touched.contactoPrincipal_request1
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo inválido
              </Form.Control.Feedback>
            </FloatingLabel>
          )}
          <FloatingLabel label="CI/RUC*">
            <Form.Control
              type="text"
              id="name_registermb"
              placeholder="CI/RUC"
              name="identificacion_request1"
              value={inputs.identificacion_request1}
              onChange={manejarCambio}
              onBlur={manejarBlur}
              isValid={
                valido.identificacion_request1 &&
                touched.identificacion_request1
              }
              isInvalid={
                !valido.identificacion_request1 &&
                touched.identificacion_request1
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Cédula inválida
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Nombre y Apellido / Razón Social*">
            <Form.Control
              type="text"
              id="name_registermb"
              name="nombre_request1"
              placeholder="Nombre y Apellido / Razón Social"
              value={inputs.nombre_request1}
              onChange={manejarCambio}
              onBlur={manejarBlur}
              isValid={valido.nombre_request1 && touched.nombre_request1}
              isInvalid={!valido.nombre_request1 && touched.nombre_request1}
              required
            />
            <Form.Control.Feedback type="invalid">
              Campo inválido
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Teléfono Convencional">
            <Form.Control
              type="text"
              id="name_registermb"
              name="telefonoConvencional_request1"
              placeholder="Teléfono Convencional"
              value={inputs.telefonoConvencional_request1}
              onChange={manejarCambio}
              onBlur={manejarBlur}
              isValid={
                valido.telefonoConvencional_request1 &&
                touched.telefonoConvencional_request1
              }
              isInvalid={
                !valido.telefonoConvencional_request1 &&
                touched.telefonoConvencional_request1
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Teléfono inválido
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Teléfono Celular*">
            <Form.Control
              type="text"
              id="name_registermb"
              name="telefonoCelular_request1"
              placeholder="Teléfono Celular"
              value={inputs.telefonoCelular_request1}
              onChange={manejarCambio}
              onBlur={manejarBlur}
              isValid={
                valido.telefonoCelular_request1 &&
                touched.telefonoCelular_request1
              }
              isInvalid={
                !valido.telefonoCelular_request1 &&
                touched.telefonoCelular_request1
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Celular inválido
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Correo Electrónico*">
            <Form.Control
              type="text"
              id="ncorreotermb"
              name="correoElectronico_request1"
              placeholder="Correo Electrónico"
              value={inputs.correoElectronico_request1}
              onChange={manejarCambio}
              onBlur={manejarBlur}
              isValid={
                valido.correoElectronico_request1 &&
                touched.correoElectronico_request1
              }
              isInvalid={
                !valido.correoElectronico_request1 &&
                touched.correoElectronico_request1
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Correo inválido
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Dirección">
            <Form.Control
              type="text"
              id="name_registermb"
              name="direccion_request1"
              placeholder="Dirección"
              value={inputs.direccion_request1}
              onChange={manejarCambio}
              onBlur={manejarBlur}
              isValid={valido.direccion_request1 && touched.direccion_request1}
              isInvalid={
                !valido.direccion_request1 && touched.direccion_request1
              }
            />
            <Form.Control.Feedback type="invalid">
              Dirección inválida
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel label="Fecha de nacimiento*">
            <Form.Control
              type="date"
              className="form-control"
              id="floatingDateInput"
              name="fechaNacimiento_request1"
              value={inputs.fechaNacimiento_request1}
              isValid={
                valido.fechaNacimiento_request1 &&
                touched.fechaNacimiento_request1
              }
              isInvalid={
                !valido.fechaNacimiento_request1 &&
                touched.fechaNacimiento_request1
              }
              onChange={manejarCambio}
              onBlur={manejarBlur}
              required
            />
            <Form.Control.Feedback type="invalid">
              Fecha inválida
            </Form.Control.Feedback>
          </FloatingLabel>
        </div>
        <hr className="formulario-separador" />

        {inputs.tipoPlan_request1 === "grupal" &&
          Array.from({ length: beneficiariosCount }).map((_, index) => (
            <div className="row fix-width" key={index}>
              <h3>Datos de Beneficiario Secundario #{index + 1}</h3>
              <div className="col col-12 mb-3">
                <FloatingLabel label="Nombre y Apellido *">
                  <Form.Control
                    type="text"
                    name={`Nombre_Bnf_SEC_REQ_1.${index}`}
                    placeholder="Nombre y Apellido*"
                    value={inputs[`Nombre_Bnf_SEC_REQ_1.${index}`] || ""}
                    onChange={manejarCambioDinamic}
                    onBlur={() =>
                      manejarBlurDinamic("Nombre_Bnf_SEC_REQ_1.", index)
                    }
                    isValid={
                      valido[`Nombre_Bnf_SEC_REQ_1.${index}`] &&
                      touched[`Nombre_Bnf_SEC_REQ_1.${index}`]
                    }
                    isInvalid={
                      !valido[`Nombre_Bnf_SEC_REQ_1.${index}`] &&
                      touched[`Nombre_Bnf_SEC_REQ_1.${index}`]
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Nombre inválido
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col col-12 col-lg-4 mb-3">
                <FloatingLabel label="Cédula*">
                  <Form.Control
                    type="text"
                    name={`Cedula_Bnf_SEC_REQ_1.${index}`}
                    placeholder="Cédula*"
                    value={inputs[`Cedula_Bnf_SEC_REQ_1.${index}`] || ""}
                    onChange={manejarCambioDinamic}
                    onBlur={() =>
                      manejarBlurDinamic("Cedula_Bnf_SEC_REQ_1.", index)
                    }
                    isValid={
                      valido[`Cedula_Bnf_SEC_REQ_1.${index}`] &&
                      touched[`Cedula_Bnf_SEC_REQ_1.${index}`]
                    }
                    isInvalid={
                      !valido[`Cedula_Bnf_SEC_REQ_1.${index}`] &&
                      touched[`Cedula_Bnf_SEC_REQ_1.${index}`]
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Cédula inválida
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col col-12 col-lg-4 mb-3">
                <FloatingLabel label="Fecha de nacimiento*">
                  <Form.Control
                    type="date"
                    name={`fechaNAC.${index}`}
                    placeholder="Fecha de nacimiento *"
                    value={inputs[`fechaNAC.${index}`] || ""}
                    onChange={manejarCambioDinamic}
                    onBlur={() => manejarBlurDinamic("fechaNAC.", index)}
                    isValid={
                      valido[`fechaNAC.${index}`] &&
                      touched[`fechaNAC.${index}`]
                    }
                    isInvalid={
                      !valido[`fechaNAC.${index}`] &&
                      touched[`fechaNAC.${index}`]
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Fecha inválida
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col col-12 col-lg-4 mb-3">
                <h3>Sexo *</h3>
                <Form.Check
                  inline
                  label="Hombre"
                  name={`Sexo_SEC_1.${index}`}
                  type="radio"
                  value="Masculino"
                  checked={inputs[`Sexo_SEC_1.${index}`] === "Masculino"}
                  onChange={manejarCambioDinamic}
                  onBlur={() => manejarBlurDinamic("Sexo_SEC_1.", index)}
                  isValid={
                    valido[`Sexo_SEC_1.${index}`] &&
                    touched[`Sexo_SEC_1.${index}`]
                  }
                  isInvalid={
                    !valido[`Sexo_SEC_1.${index}`] &&
                    touched[`Sexo_SEC_1.${index}`]
                  }
                  required
                />
                <Form.Check
                  inline
                  label="Mujer"
                  name={`Sexo_SEC_1.${index}`}
                  type="radio"
                  value="Femenino"
                  checked={inputs[`Sexo_SEC_1.${index}`] === "Femenino"}
                  onChange={manejarCambioDinamic}
                  onBlur={() => manejarBlurDinamic("Sexo_SEC_1.", index)}
                  isValid={
                    valido[`Sexo_SEC_1.${index}`] &&
                    touched[`Sexo_SEC_1.${index}`]
                  }
                  isInvalid={
                    !valido[`Sexo_SEC_1.${index}`] &&
                    touched[`Sexo_SEC_1.${index}`]
                  }
                  required
                />
              </div>
              {beneficiariosCount === index + 1 && beneficiariosCount < 9 && (
                <div
                  className="request-btn-agg-more col col-12 col-lg-5 me-4  mb-3 mb-lg-0"
                  onClick={() => handleAddBenefitprev(index + 1)}
                >
                  <span>
                    <i className="bi bi-plus-circle"></i> Añadir
                  </span>
                </div>
              )}
              {beneficiariosCount !== 1 && (
                <div
                  className="request-btn-agg-more col col-12 col-lg-5"
                  onClick={() => handleSubsprerBenefit(index)}
                >
                  <span>
                    <i className="bi bi-trash"></i> Eliminar
                  </span>
                </div>
              )}
            </div>
          ))}
        {beneficiariosCount >= 9 && (
          <h3 className=" text-danger">*Número máximo de beneficiarios*</h3>
        )}

        <div className="request-formulario-seccion2">
          {/* <label className="formulario-checkbox"></label> */}
          <Form.Check
            inline
            label={text.terms.content}
            name="aceptoTerminos_request1"
            type="checkbox"
            onChange={manejarCambio}
            isValid={
              valido.aceptoTerminos_request1 && touched.aceptoTerminos_request1
            }
            isInvalid={
              !valido.aceptoTerminos_request1 && touched.aceptoTerminos_request1
            }
            required
          />
          <a
            href="/legal"
            target="_blank"
            // onClick={() => navigate("/legal")}
            className="formulario-description"
          >
            <span>{text.terms.legal}</span>
          </a>
        </div>
        <div className="request-formulario-seccion2 mb-5">
          <button type="submit" className="formulario-boton">
            {text.action}
          </button>
        </div>
      </form>
      {/* <button onClick={handleSee}>press to show</button> */}
    </div>
  );
};

export default withTranslation("common")(Formulario);
