/** @format */

import React from "react";
import "@styles/payment.css";

const PayConfirmation = ({ errorCode }) => {
  // const [inputs, setInputs] = React.useState(
  //   JSON.parse(localStorage.getItem("inputs"))
  // );

  // const handleRedirect = async () => {
  //   const response = await fetch(
  //     `${process.env.REACT_APP_URL_SERVER}/payments/payAmount`,
  //     {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: "0976456771",
  //         card_token: "twqeq",
  //         amount: 100,
  //         amount_with_tax: 112,
  //         amount_without_tax: 100,
  //         tax_value: 0.12,
  //       }),
  //     }
  //   );
  //
  // };

  React.useEffect(() => {
    setTimeout(() => {
      window.location.href = "/planes";
    });
  });

  return (
    <div className="container-fluid">
      {errorCode && (
        <>
          <div className="pay-section">
            <div class="pay-check">
              <i class="bi bi-check2-circle"></i>
              <div className=" pay-pago">Pago realizado</div>
            </div>
            <div class="pay-check">
              <i class="bi bi-check2-circle"></i>
              <div className=" pay-pago">Contrato generado</div>
            </div>
            <div class="pay-check">
              <i class="bi bi-check2-circle"></i>
              <div className=" pay-pago">Factura y cobro generados</div>
            </div>
          </div>
        </>
      )}
      {!errorCode && (
        <>
          <div className="pay-section">
            <div class="pay-checkw">
              <i class="bi bi-patch-exclamation"></i>
              <div className=" pay-pago">"Pago no realizado"</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PayConfirmation;
