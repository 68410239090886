/** @format */

import React, { useState, useEffect } from "react";
import { createSeller } from "@services/sellers";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { notify } from "@services/feedback";
import InputGroup from "react-bootstrap/InputGroup";
import { setCronJob } from "@services/productos";
import { getContractsBySeller } from "@services/contracts";
import { getPaymentRequest } from "@services/payments";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "@styles/dashConfig.css";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const DashConfig = () => {
  const { success } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [formData, setFormData] = useState({
    email: "",
  });
  const [formDataUnique, setFormDataUnique] = useState({
    email: "",
  });
  const [formDataValid, setFormDataValid] = useState({
    email: false,
  });
  const [formDataValidUnique, setFormDataValidUnique] = useState({
    email: false,
  });
  const [formDataSended, setFormDataSended] = useState(false);
  const [formDataSendedUnique, setFormDataSendedUnique] = useState(false);
  const [formCronJob, setFormCronJob] = useState({
    day1: 0,
    day2: 0,
    day3: 0,
  });
  const [formCronJobValid, setFormCronJobValid] = useState({
    day1: false,
    day2: false,
    day3: false,
  });
  const [formCronJobSended, setFormCronJobSended] = useState(false);
  const handleInputChangeunique = (event) => {
    setFormDataUnique({
      ...formDataUnique,
      [event.target.name]: event.target.value,
    });
    fullValues(event.target.value);
  };
  const handleInputblurunique = (event) => {
    validateFormDataUnique();
  };
  const fullValues = async (email) => {
    let values = await getContractsBySeller(email, Cookies.get("token"));
    let tmp_options = values.map((item) => {
      let label = "";
      if (200007 > item.id && item.id >= 100003) {
        label = `Silver ${
          item.id[item.id.length - 1] % 2 === 0 ? "GRP" : "IND"
        } - ${item.id}`;
      } else if (300000 > item.id && item.id >= 200007) {
        label = `Gold ${
          item.id[item.id.length - 1] % 2 === 0 ? "GRP" : "IND"
        } - ${item.id}`;
      } else if (item.id >= 300000) {
        label = `Diamond ${
          item.id[item.id.length - 1] % 2 === 0 ? "GRP" : "IND"
        } - ${item.id}`;
      } else {
        label = "No tiene contrato";
      }
      const value = item.id;

      return {
        label,
        value,
      };
    });

    setOptions(tmp_options);
  };
  const validateFormDataUnique = () => {
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let validEmail = regexEmail.test(formDataUnique.email);
    setFormDataValidUnique({ ...formDataValidUnique, email: validEmail });
  };
  async function activateSeller() {
    const response = await createSeller(formData);
    if (response.status === 200) {
      notify("Vendedor activado exitosamente");
    } else {
      notify("Correo de vendedor ya registrado");
    }

    return response;
  }
  const validateFormData = () => {
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let validEmail = regexEmail.test(formData.email);
    setFormDataValid({ ...formDataValid, email: validEmail });
  };

  const handleInputblurvend = (event) => {
    validateFormData();
  };
  const handleInputChangevend = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitRegisterSeller = (event) => {
    event.preventDefault();

    setFormDataSended(true);
    activateSeller();
  };
  const validateFromCronJob = () => {
    setFormCronJobValid((prevState) => {
      let newFormCronJobValid = { ...prevState };

      if (
        formCronJob.day1 < formCronJob.day2 &&
        formCronJob.day1 < formCronJob.day3
      ) {
        newFormCronJobValid.day1 = true;
      } else {
        newFormCronJobValid.day1 = false;
      }

      if (
        formCronJob.day2 > formCronJob.day1 &&
        formCronJob.day2 < formCronJob.day3
      ) {
        newFormCronJobValid.day2 = true;
      } else {
        newFormCronJobValid.day2 = false;
      }

      if (
        formCronJob.day3 > formCronJob.day1 &&
        formCronJob.day3 > formCronJob.day2
      ) {
        newFormCronJobValid.day3 = true;
      } else {
        newFormCronJobValid.day3 = false;
      }

      return newFormCronJobValid;
    });
  };

  const handleBlurCronJobsubmit = (event) => {
    validateFromCronJob();
  };
  const handleInputChangeCronJobsubmit = (event) => {
    setFormCronJob({
      ...formCronJob,
      [event.target.name]: parseInt(event.target.value),
    });
  };
  const handleCronJobsubmit = async (event) => {
    event.preventDefault();

    setFormCronJobSended(true);

    const responseCronJob = await setCronJob(formCronJob);
    if (responseCronJob[1] === 200) {
      notify("Cron Job actualizado exitosamente");
    } else {
      notify("Error al actualizar el cron job");
    }
  };

  useEffect(() => {
    async function fetchData() {}
    fetchData();
    if (success && success === 1) {
      notify("Pago realizado exitosamente");
    }
    if (success && success === 0) {
      notify("Error al realizar el pago");
    }
  }, [success]);

  useEffect(() => {
    if (options.length > 0) {
      setSelectedOption(options[0].value);
    }
  }, [options]);

  useEffect(() => {}, [selectedOption]);

  const handleActivatePR = async (event) => {
    event.preventDefault();
    const respone = await getPaymentRequest(
      "dpaulsoria@gmail.com",
      selectedOption,
      "Pago unico"
    );
    if (respone.success) {
      window.location.href = respone.url;
    } else {
      notify("Error al obtener el contrato");
    }
  };

  const handleSubmitunique = async (event) => {
    event.preventDefault();
    setFormDataSendedUnique(true);
  };
  return (
    <>
      <section className="dashconfig-container">
        <div className="dashConifg-card">
          <Tabs
            defaultActiveKey="register"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="register" title="Agregar un nuevo vendedor">
              <form
                onSubmit={handleSubmitRegisterSeller}
                className="dash-content-card row"
              >
                <div className="col col-12">
                  <FloatingLabel label="Email" className="mb-3">
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      onChange={handleInputChangevend}
                      onBlur={handleInputblurvend}
                      placeholder="name@example.com"
                      isValid={formDataValid.email && formDataSended}
                      isInvalid={!formDataValid.email && formDataSended}
                    />
                  </FloatingLabel>
                </div>
                <div className="col col-12 master-btn-section">
                  <button className="master-btn-sub" type="submit">
                    Activar
                  </button>
                </div>
              </form>
            </Tab>
            <Tab
              eventKey="crob"
              title="Modificar reintentos de cobro de tarjeta"
            >
              <form
                className=" row dash-content-card"
                onSubmit={handleCronJobsubmit}
              >
                <div className="col col-12 col-xxl-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Intento #1
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="4"
                      aria-label="Username"
                      name="day1"
                      aria-describedby="basic-addon1"
                      onChange={handleInputChangeCronJobsubmit}
                      onBlur={handleBlurCronJobsubmit}
                      isValid={formCronJobValid.day1 && formCronJobSended}
                      isInvalid={!formCronJobValid.day1 && formCronJobSended}
                    />
                  </InputGroup>
                </div>
                <div className="col col-12 col-xxl-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Intento #2
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="10"
                      aria-label="Username"
                      name="day2"
                      aria-describedby="basic-addon1"
                      onChange={handleInputChangeCronJobsubmit}
                      onBlur={handleBlurCronJobsubmit}
                      isValid={formCronJobValid.day2 && formCronJobSended}
                      isInvalid={!formCronJobValid.day2 && formCronJobSended}
                    />
                  </InputGroup>
                </div>
                <div className="col col-12 col-xxl-4">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Intento #3
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="20"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="day3"
                      onChange={handleInputChangeCronJobsubmit}
                      onBlur={handleBlurCronJobsubmit}
                      isValid={formCronJobValid.day3 && formCronJobSended}
                      isInvalid={!formCronJobValid.day3 && formCronJobSended}
                    />
                  </InputGroup>
                </div>
                <div className="col col-12 master-btn-section">
                  <button className="master-btn-sub" type="submit">
                    Guardar
                  </button>
                </div>
              </form>
            </Tab>
            {/* <Tab eventKey="bill" title="Pago único">
              <form
                onSubmit={handleSubmitunique}
                className="dash-content-card row"
              >
                <div className="col col-6">
                  <FloatingLabel label="Email" className="mb-3">
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      onChange={handleInputChangeunique}
                      onBlur={handleInputblurunique}
                      placeholder="name@example.com"
                      isValid={formDataUnique.email && formDataSendedUnique}
                      isInvalid={!formDataUnique.email && formDataSendedUnique}
                    />
                  </FloatingLabel>
                </div>
                <div className="col col-6">
                  <FloatingLabel label="Selecciona un id de contrato">
                    <Form.Select
                      value={selectedOption}
                      onChange={handleSelectChange}
                      disabled={options.length === 0}
                    >
                      {options.length > 0 &&
                        options.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </FloatingLabel>
                </div>
                <div
                  onClick={handleActivatePR}
                  className="col col-12 master-btn-section"
                >
                  <button className="master-btn-sub" type="submit">
                    Activar
                  </button>
                </div>
              </form>
            </Tab> */}
          </Tabs>
        </div>
      </section>
    </>
  );
};
export default DashConfig;
