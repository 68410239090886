/** @format */

/** @format */

let products = {
  name: "",
  anexomv: false,
  anexoenv: false,
  type: "",
};

export async function getServiceProducts() {
  // sacarlo del localStorage
  if (!localStorage.getItem("products")) {
    localStorage.setItem("products", JSON.stringify(products));
  } else {
    // devolver en json
    return JSON.parse(localStorage.getItem("products"));
  }
  return products;
}

export async function setServiceProduct(value, parameter) {
  products[parameter] = value;
  localStorage.setItem("products", JSON.stringify(products));
}
