/** @format */

// * @format */

import React, { useState } from "react";
import adminGreenIcon from "../assets/images/green-admin.png";
import adminWhiteIcon from "../assets/images/white-admin.png";
import sellerGreenIcon from "../assets/images/green-seller.png";
import sellerWhiteIcon from "../assets/images/white-seller.png";
import { useNavigate } from "react-router-dom";
import "@styles/home.css";

import { withTranslation } from "react-i18next";

const Home = () => {
  const [adminHovered, setAdminHovered] = useState(false);
  const [sellerHovered, setSellerHovered] = useState(false);
  const navigate = useNavigate();

  const handleAdminHover = () => {
    setAdminHovered(true);
  };

  const handleSellerHover = () => {
    setSellerHovered(true);
  };

  const handleAdminLeave = () => {
    setAdminHovered(false);
  };

  const handleSellerLeave = () => {
    setSellerHovered(false);
  };

  const handleClickSeller = () => {
    localStorage.setItem("confirmRole", 1);
    navigate("/login", { replace: true });
  };

  const handleClickBoss = () => {
    localStorage.setItem("confirmRole", 0);
    navigate("/login", { replace: true });
  };

  return (
    <>
      <div className="home-hero-img"></div>
      <div className="home-overlay"></div>

      <div className="home-home">
        <div
          className="home-card"
          onMouseOver={handleAdminHover}
          onMouseOut={handleAdminLeave}
          onClick={handleClickBoss}
        >
          <img
            src={adminHovered ? adminWhiteIcon : adminGreenIcon}
            alt="Card"
          />
          <div className="home-card-content">
            <h3>Administrador</h3>
            <p className="home-text-content container d-flex justify-content-center align-items-center">
              Acceda a su cuenta y administre los contratos de nuestros clientes
            </p>
          </div>
          <div className="home-btn">Acceder</div>
        </div>
        <div
          className="home-card"
          onMouseOver={handleSellerHover}
          onMouseOut={handleSellerLeave}
          onClick={handleClickSeller}
        >
          <img
            src={sellerHovered ? sellerWhiteIcon : sellerGreenIcon}
            alt="Card"
          />
          <div className="home-card-content">
            <h3>Vendedor</h3>
            <p className="home-text-content container d-flex justify-content-center align-items-center">
              Acceda a su cuenta y asesore a nuestros clientes en la
              contratación del plan que mes se adapte a sus necesidades
            </p>
          </div>
          <div className="home-btn">Acceder</div>
        </div>
      </div>
    </>
  );
};

export default withTranslation("common")(Home);
