/** @format */

import { useNavigate } from "react-router-dom";
import "@styles/unauthorized.css";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="unauthorized-unauthorized">
        <i className="bi bi-exclamation-octagon-fill unaut-img"></i>
        <div className="unauthorized-info">
          <h1>No autorizado</h1>
          <span>
            Esta página no está disponible para ti, debes de acceder con tus
            credenciales
          </span>
          <button
            className="aut-btn"
            onClick={() => navigate("/login", { replace: true })}
          >
            Regresar
          </button>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
