/** @format */

const URL_SERVER = process.env.REACT_APP_URL_SERVER;

export async function sendContract(name, email, sellerEmail, contractType) {
  const response = await fetch(`${URL_SERVER}/contracts/sendContract`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      sellerEmail,
      contractType,
    }),
  });
  const data = await response.json();
  return data;
}

export async function getContracts() {
  const response = await fetch(`${URL_SERVER}/contracts/getContracts`);
  const data = await response.json();
  return data;
}

export async function getContractsBySeller(email, token) {
  const response = await fetch(
    `${URL_SERVER}/contracts/getContractsBySeller?email=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
}
export async function getBillingBySeller(email) {
  const response = await fetch(
    `${URL_SERVER}/reportes/billingsBySeller?email=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function updateDebt(id, cant = 1) {
  const response = await fetch(`${URL_SERVER}/contracts/updateDebt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      cant
    }),
  });
  const data = await response.json();
  return data;
}
export async function inactiveContract(id) {
  try {
    const response = await fetch(`${URL_SERVER}/contracts/off`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    });
    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.log("Error al inactivar el contrato");
    return { success: false, data: null };
  }
}
