/** @format */

import { Outlet } from "react-router-dom";
import Header from "@components/widgets/header";
import Footer from "@components/widgets/footer";
import Bubbles from "@components/widgets/bubbles";
import Navfooter from "./widgets/navfooter";
const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <Bubbles />
      <Navfooter />
    </>
  );
};

export default Layout;
