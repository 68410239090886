/** @format */

import React from "react";

import { withTranslation, useTranslation } from "react-i18next";
import ProductsBanner from "./productsBanner";
import CustomModal from "./modals/modal";
import ModalInfo from "./modals/modalInfo";
// import { getBaseProducts, getProducts } from "../services/productos";
import { getBaseAll, getAnexoAll } from "@services/productos";
import "@styles/tienda.css";

function randomNumber(min = 10, max = 1000) {
  return Math.floor(Math.random() * (max - min)) + min;
}

const Tienda = ({ modal }) => {
  const [t] = useTranslation("common");
  const [products, setProducts] = React.useState({});
  const [switche, setSwitche] = React.useState(true);
  const [style1, setStyle1] = React.useState("tienda-section-on");
  const [style2, setStyle2] = React.useState("tienda-section-off");
  const [anexos, setAnexos] = React.useState({});
  React.useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await getBaseAll();
        if (response.status === 200) {
          setProducts(response.products);
        }
      } catch (error) {}
    };

    const getAnexos = async () => {
      try {
        const response = await getAnexoAll();
        if (response.status === 200) {
          setAnexos(response.anexos);
        }
      } catch (error) {}
    };
    getPrices();
    getAnexos();
  }, []);
  const handleIndividual = () => {
    if (!switche) {
      setStyle1("tienda-section-on");
      setStyle2("tienda-section-off");
      setSwitche(true);
    }
  };
  const handleGrupal = () => {
    if (switche) {
      setStyle1("tienda-section-off");
      setStyle2("tienda-section-on");
      setSwitche(false);
    }
    localStorage.setItem("cantBnf", 1);
  };

  return (
    <>
      <div className="productsBanner-hero-img-section">
        <h2>{t("plans.title")}</h2>
      </div>
      <div className="productsBanner-section">
        <div id="planes" className="productsBanner-products-section">
          <div className="tienda-container-swith">
            <div className={style1}>
              <span onClick={handleIndividual}>Individual</span>
            </div>

            <div className={style2}>
              <span onClick={handleGrupal}>Grupal</span>
            </div>
          </div>

          {switche && (
            <>
              <div
                className="productsBanner-products"
                id={`product-Individual-0`}
              >
                <div className="productsBanner-products-title">
                  <div className="productsBanner-products--span">
                    <p>1 Beneficiario</p>
                  </div>
                </div>
                <div className="productsBanner-products-item">
                  {t("plans.list.individual.products", {
                    returnObjects: true,
                  }).map((product, index) => {
                    return products[product.code] ? (
                      <ProductsBanner
                        db={products[product.code].precio}
                        key={`plan_Individual_${index}_${product.title}-${
                          product.code
                        }_${randomNumber()}`}
                        data={product}
                        code="Individual"
                        benefitsBool={true}
                        actionBool={true}
                        name="Individual"
                      />
                    ) : (
                      <div key={`${index}`}>Loading Product...</div>
                    );
                  })}
                </div>
                {t("plans.list.individual.anexos", { returnObjects: true }).map(
                  (anexo, index) => {
                    return (
                      <div
                        key={`b${index}`}
                        className="productsBanner-products productsBanner-anexos"
                        id={`product-${anexo.name}`}
                      >
                        <div className="productsBanner-products-title">
                          <div className="productsBanner-products--title">
                            <h1>{anexo.name}</h1>
                          </div>
                        </div>
                        <div className="productsBanner-products-section">
                          <div className="productsBanner-products-item">
                            {anexo.products.map((product, index1) => {
                              return anexos[product.code] ? (
                                <ProductsBanner
                                  db={anexos[product.code].precio}
                                  key={`plan_${index}${index1}${product.title}${
                                    anexo.code
                                  }${product.code}${index1}_${randomNumber()}`}
                                  data={product}
                                  code={`anexo`}
                                  benefitsBool={false}
                                  actionBool={false}
                                />
                              ) : (
                                <div key={`a${index} ${index1}`}>
                                  Loading Product...
                                </div>
                              );
                            })}
                          </div>
                          <div className="productsBanner-plans-action">
                            <ModalInfo
                              classPrefix={`productsBanner`}
                              code={anexo.code}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}

          {!switche && (
            <>
              <div className="productsBanner-products" id={`product-Grupal-1`}>
                <div className="productsBanner-products-title">
                  <div className="productsBanner-products--span">
                    <p>+1 Beneficiario</p>
                  </div>
                </div>

                <div className="productsBanner-products-item">
                  {t("plans.list.grupal.products", { returnObjects: true }).map(
                    (product, index) => {
                      return products[product.code] ? (
                        <ProductsBanner
                          db={products[product.code].precio}
                          key={`plan_Grupal_${index}_${product.title}-${
                            product.code
                          }_${randomNumber()}`}
                          data={product}
                          code="Grupal"
                          benefitsBool={true}
                          actionBool={true}
                          name="Grupal"
                        />
                      ) : (
                        <div>Loading Product...</div>
                      );
                    }
                  )}
                </div>
                {t("plans.list.grupal.anexos", { returnObjects: true }).map(
                  (anexo, index) => {
                    return (
                      <div
                        key={`d${index}`}
                        className="productsBanner-products productsBanner-anexos"
                        id={`product-${anexo.name}`}
                      >
                        <div className="productsBanner-products-title">
                          <div className="productsBanner-products--title">
                            <h1>{anexo.name}</h1>
                          </div>
                        </div>
                        <div className="productsBanner-products-section">
                          <div className="productsBanner-products-item">
                            {anexo.products.map((product, index1) => {
                              return anexos[product.code] ? (
                                <ProductsBanner
                                  db={anexos[product.code].precio}
                                  key={`plan_${index}${product.title}${
                                    anexo.code
                                  }${product.code}${randomNumber()}_${index1}`}
                                  data={product}
                                  code={`anexo`}
                                  benefitsBool={false}
                                  actionBool={false}
                                />
                              ) : (
                                <div>Loading Product...</div>
                              );
                            })}
                          </div>
                          <div className="productsBanner-plans-action">
                            <ModalInfo
                              classPrefix={`productsBanner`}
                              code={anexo.code}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
          <div className="productsBanner-modals-section">
            <CustomModal
              classPrefix={`productsBanner`}
              modal={t("modals.benefits", { returnObjects: true })}
            />
            <CustomModal
              classPrefix={`productsBanner`}
              modal={t("modals.conditions", { returnObjects: true })}
            />
            <CustomModal
              classPrefix={`productsBanner`}
              modal={t("modals.requirements", { returnObjects: true })}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation("common")(Tienda);
