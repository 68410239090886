/** @format */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { withTranslation, useTranslation } from "react-i18next";
import logo from "@images/logo.png";
import "@styles/header.css";

const Header = () => {
  const [t, i18n] = useTranslation("common");
  const header = t("header", { returnObjects: true });
  useEffect(() => {
    i18n.changeLanguage("es");
  }, [i18n]);

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(header.first.link, { replace: true });
  };

  const handlePlanesRedirect = () => {
    navigate(header.second[1].link, { replace: true });
  };

  return (
    <>
      <div className="header-header">
        <div className="header-sw-logo">
          <img
            id={header.first.id}
            src={logo}
            alt={header.first.alt}
            onClick={handleRedirect}
          />
        </div>
        <div className="header-content d-none d-lg-flex">
          {header.second.map((item, index) => {
            if (item.navigate) {
              return (
                <div
                  id={item.id}
                  key={`header_${index}`}
                  onClick={handlePlanesRedirect}
                  className="header-cp"
                >
                  {item.content}
                </div>
              );
            } else {
              return (
                <a id={item.id} key={`header_${index}`} href={item.link}>
                  {item.content}
                </a>
              );
            }
          })}
        </div>
        <div className="header-links">
          <div
            id={header.third.id}
            className="header-black-box d-none d-lg-flex"
          >
            <a href={header.third.link}>{header.third.content}</a>
          </div>
          <div className="header-black-box-ham d-lg-none ">
            <DropdownButton
              variant="secondary"
              id="dropdown-basic-button"
              title="☰"
            >
              {header.second.map((item, index) => {
                if (item.navigate) {
                  return (
                    <Dropdown.Item
                      key={`header_dd_${index}`}
                      id={`id_dd_${item.id}`}
                      onClick={handlePlanesRedirect}
                    >
                      {item.content}
                    </Dropdown.Item>
                  );
                } else {
                  return (
                    <Dropdown.Item
                      key={`header_dd_${index}`}
                      id={`id_dd_${item.id}`}
                      href={item.link}
                    >
                      {item.content}
                    </Dropdown.Item>
                  );
                }
              })}
            </DropdownButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation("common")(Header);
