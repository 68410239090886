import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import { resetPassword } from "../services/users";

const { useLocation, useNavigate } = require("react-router-dom");

const ResetPassword = () => {
  const [show, setShow] = React.useState(false);
  const [formData, setFormData] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const regex = {
    password:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    confirmPassword: /^[\s\S]*$/,
  };

  const [valido, setValido] = React.useState({
    password: false,
    confirmPassword: false,
  });

  const [touched, setTouched] = React.useState({
    password: false,
    confirmPassword: false,
  });

  const validarInput = (nombreCampo, valor) => {
    if (regex[nombreCampo].test(valor)) {
      setValido((prevValido) => ({ ...prevValido, [nombreCampo]: true }));
    } else {
      setValido((prevValido) => ({ ...prevValido, [nombreCampo]: false }));
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    validarInput(event.target.name, event.target.value);
  };

  const manejarBlur = (event) => {
    const { name, value } = event.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validarInput(name, value);
  };

  const handleRequest = async (event) => {
    event.preventDefault();
    const c1 = formData.password;
    const c2 = formData.confirmPassword;
    const formIsValid = valido.password && valido.confirmPassword;

    const passwordMath = c1 === c2;
 
    if (passwordMath && !formIsValid) {
      alert("Las contraseñas no coinciden");
      setShow(false);
      return;
    } else if (passwordMath && formIsValid) {
      const response = await resetPassword(token, c1);
      setShow(true);
    } else {
      alert("La contraseña no es válida, inténtelo de nuevo");
      setShow(false);
      return;
    }
  };

  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  }, [navigate, show]);

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Card className="refreshPassword-Card">
        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
        <Card.Body>
          <Card.Title style={{ marginTop: "1rem" }}>
            <strong>Cambia tu contraseña</strong>
          </Card.Title>
          <div style={{ margin: "2rem 0" }}>
            <FloatingLabel label="Contraseña" className="mb-3">
              <Form.Control
                id="password"
                type="password"
                placeholder="****"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                onBlur={manejarBlur}
                isValid={valido.password && touched.password}
                isInvalid={!valido.password && touched.password}
                required
              />
            </FloatingLabel>
            <FloatingLabel label="Confirmar contraseña" className="mb-3">
              <Form.Control
                id="confirmPassword"
                type="password"
                placeholder="****"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                onBlur={manejarBlur}
                isValid={
                  formData.password === formData.confirmPassword &&
                  formData.password !== "" &&
                  touched.confirmPassword
                }
                isInvalid={
                  formData.confirmPassword !== formData.password &&
                  touched.confirmPassword
                }
                required
              />
            </FloatingLabel>
          </div>
          <Button
            style={{ backgroundColor: "#3b8e80", borderColor: "#3b8e80" }}
            variant="primary"
            onClick={handleRequest}
          >
            Solicitar cambio de contraseña
          </Button>
        </Card.Body>
      </Card>
    </section>
  );
};

export default ResetPassword;
