/** @format */

import React, { useState, useEffect } from "react";

import {
  getTableReport,
  getExcelReport,
  getBillingsBySeller,
} from "@services/reportes";

import { verifySeller } from "@services/sellers";
import { useParams } from "react-router-dom";

import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";
const FilterMoney = () => {
  const [data, setData] = useState([]);
  const [ward] = useState(Cookies.get("ward"));
  const [page] = useState(1);

  async function fetchService() {
    let response = [];
    const token = Cookies.get("token");
    const seller = await verifySeller(token);
    if (ward === "1") {
      response = await getBillingsBySeller(seller.result.email);
    } else if (ward === "0") {
      response = await getTableReport("Billing", page);
    }
    return response || [];
  }
  const handleDownload = async () => {
    getExcelReport("billingsBySeller");
  };
  useEffect(() => {
    async function fetchData() {
      const data = await fetchService();
      setData(data);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="dashCust-container">
        <section className="dasCusto-card rounded">
          <div className="seller-content-header">
            <div className="seller-title">
              <span>Pagos</span>
            </div>
            <div className="pdf-down" onClick={handleDownload}>
              <i className="bi bi-filetype-xlsx"></i>{" "}
            </div>
          </div>
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr>
                {ward === "1" && (
                  <>
                    <th>Fecha registro</th>
                    <th>Nombre del cliente</th>
                    <th>Nombre del vendedor</th>
                    <th>Valor</th>
                    <th>Plan</th>
                    <th>Variante del plan</th>
                    <th>Anexo</th>
                  </>
                )}
                {ward === "0" && (
                  <>
                    <th>id</th>
                    <th>Nombre del cliente</th>
                    <th>Correo del cliente</th>
                    <th>Cédula del cliente</th>
                    <th>Teléfono del cliente</th>
                    <th>Dirección del cliente</th>
                    <th>Siguente cobro</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                ward === "1" &&
                data.map((item, index) => (
                  <tr key={`a${index}`}>
                    <td key={`b${index}`}>{item.fechaRegistro}</td>
                    <td key={`b${index}`}>{item.nombreCliente}</td>
                    <td key={`d${index}`}>{item.nombreVendedor}</td>
                    <td key={`d${index}`}>{item.valor}</td>
                    <td key={`c${index}`}>{item.nombrePlan}</td>
                    <td key={`d${index}`}>{item.variantePlan}</td>
                    <td key={`b${index}`}>{item.anexo}</td>
                  </tr>
                ))}
              {data.length > 0 &&
                ward === "0" &&
                data.map((item, index) => (
                  <tr key={`a${index}`}>
                    <td key={`b${index}`}>{item.id}</td>
                    <td key={`d${index}`}>{item.nombre}</td>
                    <td key={`b${index}`}>{item.correoElectronico}</td>
                    <td key={`c${index}`}>{item.identificacion}</td>
                    <td key={`d${index}`}>{item.telefonoConvencional}</td>
                    <td key={`b${index}`}>{item.direccion}</td>
                    <td>{item.createdAt.split("T")[0].split("-").join("/")}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </section>
      </section>
    </>
  );
};

export default FilterMoney;
