import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";

const Languajes = ["es", "en"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    debug: false,
    whitelist: Languajes,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        common: common_es,
      },
      en: {
        common: common_en, // 'common' is our custom namespace
      },
    },
  });
export default i18n;
