/** @format */

import React from "react";
import "@styles/navfooter.css";
import { useNavigate } from "react-router-dom";
const Navfooter = () => {
  const navigate = useNavigate();
  const handleClickbUY = () => {
    navigate("/planes", { replace: true });
  };
  const handleClickRgister = () => {
    navigate("/", { replace: true });
  };
  return (
    <>
      <div className="navfooter-container d-md-none">
        <div className="navfooter-section" onClick={handleClickRgister}>
          <i className="bi bi-person-fill"></i>
        </div>
        <div className="navfooter-section" onClick={handleClickbUY}>
          <i className="bi bi-bag-check"></i>
        </div>
      </div>
    </>
  );
};
export default Navfooter;
