/** @format */
let observers = [];

export function addObserver(observer) {
  observers.push(observer);
}

export function removeObserver(observer) {
  observers = observers.filter((obs) => obs !== observer);
}

export function notify(message, style = "toast-header") {
  observers.forEach((observer) => observer(message, style));
}
