/** @format */

import React from "react";
import "@styles/error.css";
const Error = () => {
  return (
    <>
      <section className="error-section">
        <div className="icon-error-section">
          <i className="bi bi-cone-striped"></i>
        </div>
        <section className="text-error-section">
          <span>Oooopss.....Error 404</span>
          <span>La página que buscas no existe</span>
        </section>
      </section>
    </>
  );
};
export default Error;
