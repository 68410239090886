/** @format */

const URL_SERVER = process.env.REACT_APP_URL_SERVER;

// Obtener productos
export const getProducts = async (product, anexos) => {
  try {
    const response = await fetch(`${URL_SERVER}/productos/base/getTest`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product,
        anexos,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error al obtener los productos");
  }
};

export const getBaseProduct = async (product) => {
  try {
    const response = await fetch(
      `${URL_SERVER}/productos/base?codigo=${product}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error al obtener los productos");
  }
};

export const getAnexoProduct = async (producto, variante, anexo) => {
  try {
    const response = await fetch(
      `${URL_SERVER}/productos/anexo?producto=${producto}&variante=${variante}&anexo=${anexo}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error al obtener los anexos");
  }
};

export const getBaseAll = async () => {
  try {
    const response = await fetch(`${URL_SERVER}/productos/base/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    localStorage.setItem("baseAll", JSON.stringify(data));
    return data;
  } catch (error) {
    throw new Error("Error al obtener los productos");
  }
};

export const getAnexoAll = async () => {
  try {
    const response = await fetch(`${URL_SERVER}/productos/anexo/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    localStorage.setItem("anexoAll", JSON.stringify(data));
    return data;
  } catch (error) {
    throw new Error("Error al obtener los anexos");
  }
};

export const sendApplication = async (
  inputs,
  dataLocalStorage,
  cantBnf,
  emailSeller
) => {
  try {

    const response = await fetch(`${URL_SERVER}/productos/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        inputs: inputs,
        products: dataLocalStorage,
        cantBnf,
        emailSeller,
      }),
    });
    const status = response.status;
    const data = await response.json();
    return { status, data };
  } catch (error) {
    throw new Error("Error al enviar la solicitud");
  }
};

export const setCronJob = async (cronJob) => {
  try {
    const response = await fetch(`${URL_SERVER}/cronjob`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cronJob,
      }),
    });
    const data = [await response.json(), response.status];
    return data;
  } catch (error) {
    throw new Error("Error al cambiar el cronjob");
  }
};
