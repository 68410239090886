/** @format */

import { Navigate, Outlet } from "react-router-dom";
import { refreshToken } from "@services/users";
import Roles from "@config/roles";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { verifySeller } from "../services/sellers";

const RequireAuth = ({ allowRoles }) => {
  const [role, setRole] = useState(null);
  const confirmRole = localStorage.getItem("confirmRole") || -1;

  useEffect(() => {
    const token = Cookies.get("token");
    verifyToken(token);
  }, []);

  const verifyToken = async (token) => {
    if (typeof token !== "undefined" && token !== "undefined") {
      const response = await refreshToken(token);
      if (response.status === 200) {
        Cookies.set("token", response.token);
        setRole(response.role);
      } else {
        Cookies.remove("token");
        setRole(Roles.UNAUTHORIZED);
      }
    } else {
      setRole(Roles.UNAUTHORIZED);
    }
  };
  const verifyPage = () => {
    let valid1 = role === parseInt(confirmRole);
    let valid2 = allowRoles.includes(role);

    return valid2 && valid1;
  };
  return role === null ? (
    <div>Loading...</div>
  ) : verifyPage() ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default RequireAuth;
