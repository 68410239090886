/** @format */

import React from "react";
import "@styles/footer.css";
import imagen from "@images/logo_footer.png";
import { withTranslation, useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("common");
  return (
    <div className="footer-section">
      <div className="footer--info">
        <div className="footer--img">
          <img src={imagen} alt="" />
        </div>
        <div className="footer--contact">
          <h3>{t("footer.contact.title")}</h3>
          <ul>
            {t("footer.contact.contact_forms", { returnObjects: true }).map(
              (item, index) => {
                return (
                  <li
                    key={`contact_form_${index}`}
                    className="footer-contact-item"
                  >
                    <h3>{item.content}</h3>
                    <p>{item.data}</p>
                  </li>
                );
              }
            )}
          </ul>
        </div>
        <div className="footer--dir">
          <h3>{t("footer.address.title")}</h3>
          <ul>
            {t("footer.address.data", { returnObjects: true }).map(
              (item, index) => {
                return (
                  <div key={`dir_${index}`} className="footer-dir-item">
                    <p>{item}</p>
                  </div>
                );
              }
            )}
          </ul>
        </div>
      </div>
      <div className="footer--copy mb-5 mb-md-0">
        <p>{t("footer.copy")}</p>
      </div>
    </div>
  );
};

export default withTranslation("common")(Footer);
