import React, { useState } from "react";
import MoreInfo from "@components/modals/moreInfo";

import { withTranslation, useTranslation } from "react-i18next";

const ModalInfo = ({ classPrefix, code }) => {
  const [t] = useTranslation("common");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);
  return (
    <>
      <div className={`${classPrefix}-accede`} onClick={handleShow}>
        {/* <a href={t("plans.action.link")}> */}
        <p>{t("plans.action.content")}</p>
      </div>
      {show && (
        <MoreInfo
          info={t(`modals.moreInfo.${code}`, {
            returnObjects: true,
          })}
          show={show}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default withTranslation("common")(ModalInfo);
