/** @format */

import React from "react";

import { Outlet } from "react-router-dom";
import SideBar from "@components/dashboard/sideBar";
import "@styles/dashboard.css";
import DashHeader from "@components/dashboard/dashHeader";

const Dashboard = () => {
  return (
    <>
      <div className="dash-container">
        <section className="dash-side">
          <SideBar></SideBar>
        </section>
        <section className="dash-content">
          <DashHeader />
          <Outlet />
        </section>
      </div>

      {/* <Routes>
        <Route path="/dashboard" element={<SideBar />} />

      </Routes> */}
    </>
  );
};
export default Dashboard;
