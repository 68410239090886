/** @format */
import React from "react";
import { useNavigate } from "react-router-dom";
import "@styles/productsBanner.css";
import { setServiceProduct } from "../services/carrito";
import Decimal from "decimal.js";

const ProductsBanner = ({
  data,
  idx,
  db,
  code,
  name,
  benefitsBool,
  actionBool,
}) => {
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const handleRedirect = () => {
    setServiceProduct(name, "type");
    setServiceProduct(data.title, "name");

    if (code !== "anexo") {
      navigate(`/planes/${data.title.toLowerCase()}-${code.toLowerCase()}`);
    }
  };
  function productMoreIva(value) {
    const valueTmp = new Decimal(value);
    const tax = new Decimal(1.12);
    const valueMoreIva = valueTmp.times(tax);
    return valueMoreIva.toFixed(2);
  }
  return (
    <div className="productsBanner-productsBanner">
      <div className="productsBanner-plans-benefits">
        <div
          className={`productsBanner-box productsBanner-background-plan-${data.title}`}
        >
          <h2>
            <strong>{data.title}</strong>
          </h2>
        </div>
        {benefitsBool && (
          <div className="productsBanner-plans-benefits-info">
            <h3>{data.benefits.title}</h3>
            <div className="productsBanner-plans-benefits-list">
              {data.benefits.list.map((element, index) => {
                return (
                  <div key={index} className="productsBanner-plan-benefit-item">
                    <p>{element}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="productsBanner-plans-price">
        <h2>${productMoreIva(parseFloat(db))}</h2>
        <span>{data.price.period}</span>
      </div>
      {actionBool && (
        <div className="productsBanner-plans-action">
          <div className="productsBanner-accede" onClick={handleRedirect}>
            <p href={data.action.link}>{data.action.content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsBanner;
