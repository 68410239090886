import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { requestRefreshPassword } from "@services/users";
import { useNavigate } from "react-router-dom";
import "@styles/refreshPassword.css";

const RefreshPassword = () => {
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const handleRequest = async (event) => {
    event.preventDefault();
    // Solo funciona si el campo de email es el primero
    // en el formulario
    const emailInput = document.getElementById("floatingInputEmail").value;

    setResponse(await requestRefreshPassword(emailInput));
  };

  useEffect(() => {
    if (response) {
      setTimeout(() => {
        navigate("/login");
      }, 7000);
    }
  }, [navigate, response]);

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      {!response ? (
        <Card className="refreshPassword-Card">
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body>
            <Card.Title>
              <strong>¿Cuál es tu cuenta?</strong>
            </Card.Title>
            <Card.Text>
              Por favor, ingresa tu correo para enviarte un link de recuperación
              de contraseña.
            </Card.Text>
            <div style={{ margin: "2rem 0" }}>
              <FloatingLabel
                controlId="floatingInputEmail"
                label="Email address"
                className="mb-3"
              >
                <Form.Control type="email" placeholder="name@example.com" />
              </FloatingLabel>
            </div>
            <Button
              style={{ backgroundColor: "#3b8e80", borderColor: "#3b8e80" }}
              variant="primary"
              onClick={handleRequest}
            >
              Solicitar cambio de contraseña
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <Card className="refreshPassword-Card">
          <Card.Body>
            <Card.Title>
              <strong>Atencion!</strong>
            </Card.Title>
            <Card.Text>
              Si el correo coinciden con un usuario registrado, se le enviará un
              correo con un link para cambiar su contraseña.
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </section>
  );
};

export default RefreshPassword;
