/** @format */

import React, { useState } from "react";
import Header from "@components/widgets/header";
import { useNavigate } from "react-router-dom";
import { createSellerUser } from "@services/users";
import "@styles/register.css";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
  });

  const [errorToken, setErrorToken] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setErrorToken("Las contraseñas no coinciden");
    } else {
      createUser();
    }
  };

  const handleRedirect = () => {
    navigate("/login", { replace: true });
  };

  async function createUser() {
    setErrorToken("");
    const response = await createSellerUser(formData);
    if (response.status === 500) {
      setErrorToken("Error con el servidor");
    } else if (response.status === 400) {
      setErrorToken(response.message);
    } else {
      handleRedirect();
    }
  }

  return (
    <>
      <Header />
      <div className="register-register-container">
        <div>
          <h1>Registro</h1>
          <form onSubmit={handleSubmit} className="register-register-form">
            <div>
              <label htmlFor="name" className="register-input">
                <span className="register-input__label">Nombre</span>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="register-input__field"
                  required
                />
              </label>
            </div>
            <div>
              <label htmlFor="email" className="register-input">
                <span className="register-input__label">Correo</span>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="register-input__field"
                  required
                />
              </label>
            </div>
            <div>
              <label htmlFor="password" className="register-input">
                <span className="register-input__label">Contraseña</span>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="register-input__field"
                  required
                />
              </label>
            </div>
            <div>
              <label htmlFor="confirmPassword" className="register-input">
                <span className="register-input__label">
                  Confirmar contraseña
                </span>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="register-input__field"
                  required
                />
              </label>
            </div>
            <div>
              <label htmlFor="token" className="register-input">
                <span className="register-input__label">Token</span>
                <input
                  type="text"
                  id="token"
                  name="token"
                  value={formData.token}
                  onChange={handleInputChange}
                  className="register-input__field"
                  required
                />
              </label>
            </div>
            <button type="submit">Crear Cuenta</button>
            {errorToken && <div style={{ color: "red" }}>{errorToken}</div>}
          </form>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
