/** @format */

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import "@styles/sideBar.css";

const SideBar = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [ward] = useState(Cookies.get("ward"));
  const spanRef = useRef(null);

  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleToggle = () => {
    setDropdown(false);
    setExpanded(!expanded);
  };

  const handleNavigation = (type) => {
    navigate(`/dashboard/reportes/${type}`, { replace: true });
  };

  return (
    <>
      <div className={`sidebar ${expanded ? "expanded" : "collapsed"}`}>
        <div className="dash-header">
          <div className="dash-header-logo">DashBoard</div>
          <div className="dash-btn-contract cpointer" onClick={handleToggle}>
            <i className=" dash-icon bi bi-list"></i>
          </div>
        </div>
        <hr></hr>
        <div className="sideb-container">
          {ward === "0" && (
            <div
              className="sideb-concern"
              onClick={() => navigate("/dashboard/config", { replace: true })}
            >
              <i className="cpointer  bi bi-award"></i>
              <span className="dashb-name cpointer">Administrador</span>
            </div>
          )}
          {ward === "0" && (
            <div
              className="sideb-concern"
              onClick={() =>
                navigate("/dashboard/reportes/seller", { replace: true })
              }
            >
              <i className="cpointer  bi bi-person-lines-fill"></i>
              <span className="dashb-name cpointer">Vendedores</span>
            </div>
          )}
          <div
            className="sideb-concern"
            onClick={() => navigate("/dashboard/customer", { replace: true })}
          >
            <i className="cpointer bi bi-person-circle"></i>
            <span className="dashb-name cpointer">Clientes</span>
          </div>
          <div
            className="sideb-concern"
            onClick={() => navigate("/dashboard/contract", { replace: true })}
          >
            <i className="cpointer bi bi-card-text"></i>
            <span className="dashb-name cpointer">Contratos</span>
          </div>

          <div
            className="sideb-concern"
            onClick={() => handleNavigation("transacciones", { replace: true })}
          >
            <i className="cpointer bi bi-receipt"></i>
            <span className="dashb-name cpointer">Transacciones</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
