/** @format */

import React from "react";
import Modal from "react-bootstrap/Modal";
import "@styles/modal.css";

const MoreInfo = ({ info, show, setShow }) => {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <div className="close" onClick={handleClose}>
            <i className="bi bi-x-lg"></i>
          </div>
          <div className="text-center pt-3 text-header">{info.title}</div>
          <ul className="text-start pt-2">
            {info.data.map((item, index) => {
              return <li key={`moreInfo_${index}`}>{item}</li>;
            })}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MoreInfo;
