/** @format */

import React, { useState } from "react";
import "@styles/checkout.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { sendApplication } from "@services/productos";
import { getServiceProducts } from "@services/carrito";
import { verifySeller } from "@services/sellers";
import { notify } from "@services/feedback";
import img_t from "@images/TarjetasCredito-Pago-SafewayLink.png";
import Cookies from "js-cookie";
const Checkout = ({ inputs, setInputs, setPage, saveInputs }) => {
  const [serviceProducts, setServiceProducts] = useState([]);
  const [products_price, setProducts_price] = useState({
    price: 0,
    anexo: 0,
    subtotal: 0,
    iva: 0,
    total: 0,
  });
  const [inputs2, setInputs2] = useState({
    name: "",
    identification: "",
    email: "",
    phone: "",
    address: "",
  });
  const [valido, setValium] = useState({
    identificacion_request2: false,
    nombre_request2: false,
    telefonoCelular_request2: false,
    correoElectronico_request2: false,
    direccion_request2: false,
  });
  const [touched, setTouched] = useState({
    identificacion_request2: false,
    nombre_request2: false,
    telefonoCelular_request2: false,
    correoElectronico_request2: false,
    direccion_request2: false,
  });
  const regex = {
    tipoPlan_request2: /^[a-zA-Z0-9\s]{1,50}$/,
    identificacion_request2: /^\d{10}$|^\d{13}$/,
    nombre_request2: /^[a-zA-Z\s]{1,100}$/,
    telefonoCelular_request2: /^\d{10}$/,
    correoElectronico_request2: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    direccion_request2: /^[a-zA-Z0-9\s,]{1,100}$/,
    aceptoTerminos_request2: /^true$/,
  };
  function handleClick() {
    notify("Ingresa con tu link de pagomedios para continuar");
  }
  const getKeyFAnex = (obj, name, type, a1, a2) => {
    let keys = "";
    for (let key in obj) {
      if (
        obj[key].plan === name.toUpperCase() &&
        obj[key].variante === type.toUpperCase()
      ) {
        if (obj[key].nombre === "ENF/ACC" && a1 && !a2) keys = key;
        if (obj[key].nombre === "MULTIVIAJES" && !a1 && a2) keys = key;
        if (obj[key].nombre === "PREMIUM" && a1 && a2) keys = key;
      }
    }

    return keys;
  };
  const getKeyFBase = (obj, name, type) => {
    let keys = "";
    for (let key in obj) {
      if (
        obj[key].nombre === name.toUpperCase() &&
        obj[key].variante === type.toUpperCase()
      ) {
        keys = key;
      }
    }

    return keys;
  };

  React.useEffect(() => {
    const localState = JSON.parse(localStorage.getItem("products"));
    const localAnex = JSON.parse(localStorage.getItem("anexoAll")).anexos;
    const localBase = JSON.parse(localStorage.getItem("baseAll")).products;

    const anexCode = getKeyFAnex(
      localAnex,
      localState.name,
      localState.type,
      localState.anexoenv,
      localState.anexomv
    );
    const baseCode = getKeyFBase(localBase, localState.name, localState.type);

    let basePrice = baseCode !== "" ? localBase[baseCode].precio : 0;
    let anexPrice = anexCode !== "" ? localAnex[anexCode].precio : 0;
    const getProducts = async () => {
      const response = await getServiceProducts();

      setServiceProducts(response);
      const subtotalb = parseFloat(basePrice) + parseFloat(anexPrice);

      const seeiva = parseFloat(subtotalb * 0.12);
      const seeTotal = parseFloat(subtotalb) + parseFloat(seeiva);
      setProducts_price({
        price: basePrice,
        anexo: anexPrice,
        subtotal: subtotalb.toFixed(2),
        iva: seeiva.toFixed(2),
        total: seeTotal.toFixed(2),
      });
    };
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(touched).forEach((t) => {
      if (inputs[t] !== "") {
        setTouched((prevTouched) => ({ ...prevTouched, [t]: true }));
        validarInput(t, inputs[t]);
      }
    });
  }, []);

  const validarInput = (nombreCampo, valor) => {
    if (regex[nombreCampo].test(valor)) {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: true }));
    } else {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: false }));
    }
  };
  const handleswith = (event) => {
    const propertyNames = {
      nombre_request2: "nombre_request1",
      identificacion_request2: "identificacion_request1",
      telefonoCelular_request2: "telefonoCelular_request1",
      correoElectronico_request2: "correoElectronico_request1",
      direccion_request2: "direccion_request1",
    };
    const propertyNamesrever = {
      nombre_request2: "name",
      identificacion_request2: "identification",
      telefonoCelular_request2: "phone",
      correoElectronico_request2: "email",
      direccion_request2: "address",
    };
    const value = event.target.checked;
    if (value) {
      setInputs2((prevInputs) => ({
        ...prevInputs,
        name: inputs.nombre_request2,
        identification: inputs.identificacion_request2,
        phone: inputs.telefonoCelular_request2,
        email: inputs.correoElectronico_request2,
        address: inputs.direccion_request2,
      }));
      // setear el valor de este input nombre_request1
      setInputs((prevInputs) => ({
        ...prevInputs,
        nombre_request2: inputs.nombre_request1,
        identificacion_request2: inputs.identificacion_request1,
        telefonoCelular_request2: inputs.telefonoCelular_request1,
        correoElectronico_request2: inputs.correoElectronico_request1,
        direccion_request2: inputs.direccion_request1,
      }));
      Object.entries(propertyNames).forEach(([name2, name1]) => {
        const value = inputs[name1];
        setTouched((prevTouched) => ({ ...prevTouched, [name2]: true }));
        validarInput(name2, value);
      });
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        nombre_request2: inputs2.name,
        identificacion_request2: inputs2.identification,
        telefonoCelular_request2: inputs2.phone,
        correoElectronico_request2: inputs2.email,
        direccion_request2: inputs2.address,
      }));
      Object.entries(propertyNamesrever).forEach(([name2, name1]) => {
        const value = inputs2[name1];
        setTouched((prevTouched) => ({ ...prevTouched, [name2]: true }));
        validarInput(name2, value);
      });
    }
  };

  const manejarCambio = (event) => {
    const { name, value } = event.target;
    if (name === "group2_request2") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        tipoInformacion_request2: value || "",
      }));
    } else if (name === "group1_request2") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        tipoPlan_request2: value || "",
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value || "",
      }));
    }

    if (name === "nombre_request2") {
      formatNombre(value);
    }

    if (name === "identificacion_request2") {
      formatID(value, "ruc");
    }

    if (name === "telefonoCelular_request2") {
      formatTelefonoCelular(value);
    }
  };

  const formatTelefonoCelular = (input) => {
    const digitsOnly = input.replace(/\D/g, "");

    const limitedTelefonoCelular = digitsOnly.slice(0, 10);

    const formattedTelefonoCelular = limitedTelefonoCelular.startsWith("0")
      ? limitedTelefonoCelular
      : "0" + limitedTelefonoCelular;

    setInputs((prevInputs) => ({
      ...prevInputs,
      telefonoCelular_request2: formattedTelefonoCelular,
    }));
  };

  const formatID = (input, type) => {
    // Eliminar cualquier carácter que no sea un número
    const digitsOnly = input.replace(/\D/g, "");

    // Limitar la cantidad de caracteres a un máximo de 10
    const n = type === "ruc" ? 13 : 10;
    const limitedCedula = digitsOnly.slice(0, n);

    // Actualizar el estado con la cédula formateada
    setInputs((prevInputs) => ({
      ...prevInputs,
      identificacion_request2: limitedCedula,
    }));
  };

  const formatNombre = (input) => {
    // Eliminar cualquier carácter que no sea una letra o espacio
    const formattedNombre = input.replace(/[^a-zA-Z\s]/g, "");

    // Actualizar el estado con el nombre formateado
    setInputs((prevInputs) => ({
      ...prevInputs,
      nombre_request2: formattedNombre,
    }));
  };

  const manejarBlur = (event) => {
    const { name, value } = event.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validarInput(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const todosLosCamposValidos = Object.values(valido).every(
      (valor) => valor === true
    );
    if (todosLosCamposValidos) {
      saveInputs();
      const dataLocalStorage = JSON.parse(localStorage.getItem("products"));
      const cantBnf = localStorage.getItem("cantBnf");

      const sellerEmail = await verifySeller(Cookies.get("token"));

      const tmpUrl = await sendApplication(
        inputs,
        dataLocalStorage,
        parseInt(cantBnf),
        sellerEmail.result.email
      );
      if (tmpUrl.status === 200) {
        if (tmpUrl.data.pagomedios.state) {
          handleClick();
          window.location.assign(tmpUrl.data.pagomedios.url);
        }
      } else {
        notify("El correo ingresado ya existe como cliente");
      }
    } else {
      notify("Por favor, complete los campos obligatorios.");
    }
  };

  return (
    <>
      {/* <button onClick={handleInfo}> ver info</button> */}
      <div className="container-fluid mb-5">
        <div className="row justify-content-center mt-5">
          <div className="col col-12 col-lg-6 mb-5">
            <h2>Detalle de facturación</h2>
            <div className="row">
              <div className="col-auto">
                <h3 className="formulario-etiqueta d-inline">
                  Tipo de Plan:
                  <strong id="detallesFact_tipoPlan">
                    {`${serviceProducts.name} ${serviceProducts.type}`}
                  </strong>
                </h3>
              </div>
              <div className="col">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  className=""
                  onChange={handleswith}
                  label="Misma persona que el solicitante"
                />
              </div>
            </div>

            <div className="endSell-form">
              <FloatingLabel label="Nombre y Apellido / Razón Social*">
                <Form.Control
                  type="text"
                  id="name_registermb"
                  name="nombre_request2"
                  placeholder="Nombre y Apellido / Razón Social"
                  value={inputs.nombre_request2}
                  onChange={manejarCambio}
                  onBlur={manejarBlur}
                  isValid={valido.nombre_request2 && touched.nombre_request2}
                  isInvalid={!valido.nombre_request2 && touched.nombre_request2}
                  required
                />
              </FloatingLabel>
              <FloatingLabel label="CI/RUC*">
                <Form.Control
                  type="text"
                  id="name_registermb"
                  placeholder="CI/RUC"
                  name="identificacion_request2"
                  value={inputs.identificacion_request2}
                  onChange={manejarCambio}
                  onBlur={manejarBlur}
                  isValid={
                    valido.identificacion_request2 &&
                    touched.identificacion_request2
                  }
                  isInvalid={
                    !valido.identificacion_request2 &&
                    touched.identificacion_request2
                  }
                  required
                />
              </FloatingLabel>
              <FloatingLabel label="Teléfono Celular*">
                <Form.Control
                  type="text"
                  id="name_registermb"
                  name="telefonoCelular_request2"
                  placeholder="Teléfono Convencional"
                  value={inputs.telefonoCelular_request2}
                  onChange={manejarCambio}
                  onBlur={manejarBlur}
                  isValid={
                    valido.telefonoCelular_request2 &&
                    touched.telefonoCelular_request2
                  }
                  isInvalid={
                    !valido.telefonoCelular_request2 &&
                    touched.telefonoCelular_request2
                  }
                  required
                />
              </FloatingLabel>
              <FloatingLabel label="Correo Electrónico*">
                <Form.Control
                  type="text"
                  id="ncorreotermb"
                  name="correoElectronico_request2"
                  placeholder="Correo Electrónico"
                  value={inputs.correoElectronico_request2}
                  onChange={manejarCambio}
                  onBlur={manejarBlur}
                  isValid={
                    valido.correoElectronico_request2 &&
                    touched.correoElectronico_request2
                  }
                  isInvalid={
                    !valido.correoElectronico_request2 &&
                    touched.correoElectronico_request2
                  }
                  required
                />
              </FloatingLabel>
              <FloatingLabel label="Dirección*">
                <Form.Control
                  type="text"
                  id="name_registermb"
                  name="direccion_request2"
                  placeholder="Dirección"
                  value={inputs.direccion_request2}
                  onChange={manejarCambio}
                  onBlur={manejarBlur}
                  isValid={
                    valido.direccion_request2 && touched.direccion_request2
                  }
                  isInvalid={
                    !valido.direccion_request2 && touched.direccion_request2
                  }
                  required
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="col col-12 col-lg-5  endSell-conetn-center  ">
            <div className="endSell-pedido-container">
              <div className="endSell-text text-center mb-3">Tu compra</div>
              <div className="endSell-text-content-parm">
                <div className="endSell-description">
                  <div className="endSell-izq-text">Producto</div>
                  <div className="endSell-der-text">Subtotal</div>
                </div>
                <div className="endSell-description">
                  <div className="endSell-izq-text">{`${serviceProducts.name} ${serviceProducts.type}`}</div>
                  <div className="endSell-der-text">
                    ${products_price.price}
                  </div>
                </div>
                <div className="endSell-description">
                  <div className="endSell-izq-text">Anexos</div>
                  <div className="endSell-der-text">
                    ${products_price.anexo}
                  </div>
                </div>
                <div className="endSell-description">
                  <div className="endSell-izq-text">Subtotal</div>
                  <div className="endSell-der-text">
                    ${products_price.subtotal}
                  </div>
                </div>
                <div className="endSell-description">
                  <div className="endSell-izq-text">IVA</div>
                  <div className="endSell-der-text">$ {products_price.iva}</div>
                </div>
                <div className="endSell-description mb-5">
                  <div className="endSell-izq-text">Total</div>
                  <div className="endSell-der-text">
                    ${products_price.total}
                  </div>
                </div>
              </div>
              <div className="checkout-conteinter-t">
                <img className="checkout-img-t" src={img_t} alt="" />
                <span className="checkout-text">
                  Realiza el pago con cualquiera de estas tarjetas de crédito.
                </span>
              </div>
              {/* <div className="endSell-trans-banc mb-4">
                <div className="endSell-trans-title">
                  Transferencia bancaria directa
                </div>
                <div className="endSell-trans-body">
                  Realiza tu pago directamente en nuestra cuenta bancaria. Por
                  favor, usa el número del pedido como referencia de pago. Tu
                  pedido no se procesará hasta que se haya recibido el importe
                  en nuestra cuenta.
                </div>
              </div> */}
              <div className="endSell-mini-text mb-3">
                Tus datos personales se utilizarán para procesar tu compra,
                mejorar tu experiencia en esta web y otros propósitos descritos
                en nuestra política de privacidad.
              </div>
              <div onClick={handleSubmit} className="endSell-btn">
                <span>Realizar Pago</span>
              </div>
              {/* <div className="endSell-back mt-4">
                <span className="volver-carrito">Volver al carrito</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Checkout;
