/** @format */

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import "@styles/dashHeader.css";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "@images/logo.png";
import { useNavigate } from "react-router-dom";
import { logOut } from "@services/users";
import DropdownButton from "react-bootstrap/DropdownButton";
const DashHeader = () => {
  const [name, setName] = useState("");
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/", { replace: true });
  };
  const logOutf = () => {
    logoutFunction();
  };
  async function logoutFunction() {
    const response = await logOut(token);
    if (response.status === 200) {
      Cookies.remove("token");
      navigate("/login", { replace: true });
    } else {
    }
  }
  useEffect(() => {
    setName(Cookies.get("name"));
  }, []);
  return (
    <>
      <section className="dh-container">
        <div className="dh-header">
          <div className="dash-header-sw-logo">
            <img src={logo} alt="" onClick={handleRedirect} />
          </div>
          <div className="dh-profile">
            <DropdownButton id="dropdown-basic-button" title={name}>
              <Dropdown.Item
                onClick={() =>
                  navigate("/dashboard/changePss", { replace: true })
                }
              >
                Cambiar contraseña
              </Dropdown.Item>
              <Dropdown.Item onClick={logOutf}>Salir</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </section>
    </>
  );
};
export default DashHeader;
