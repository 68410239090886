/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "@styles/changePass.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import { notify } from "@services/feedback";
import { changePassword } from "@services/users";
const ChangePassword = () => {
  const navigate = useNavigate();

  function handleClick(text) {
    notify(text);
  }
  const [registerData, setRegisterData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const regex = {
    password: /.+/,
    newPassword:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    confirmPassword: /^[\s\S]*$/,
  };
  const [valido, setValium] = useState({
    newPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [touched, setTouched] = useState({
    newPassword: false,
    password: false,
    confirmPassword: false,
  });
  const validarInput = (nombreCampo, valor) => {
    if (regex[nombreCampo].test(valor)) {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: true }));
    } else {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: false }));
    }
  };
  const manejarBlur = (event) => {
    const { name, value } = event.target;
    if (name === "confirmPassword") {
      const passwordMatch =
        registerData.newPassword === registerData.confirmPassword;

      if (passwordMatch) {
        setValium((prevValido) => ({ ...prevValido, [name]: true }));
      }
    }
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validarInput(name, value);
  };
  const handleInputChangeRegister = (event) => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
    validarInput(event.target.name, event.target.value);
  };
  const handleSubmitRegister = (event) => {
    event.preventDefault();
    const formIsValid = Object.values(valido).every((value) => value);
    if (formIsValid) {
      ChangePassword();
    } else {
    }
  };
  async function ChangePassword() {
    const response = await changePassword({
      password: registerData.password,
      newPassword: registerData.newPassword,
      token: Cookies.get("token"),
    });
    if (response.valid) {
      handleClick("Cambio de contraseña exitoso");
      navigate("/login");
    } else {
      handleClick("Verifica tus datos");
    }
  }
  return (
    <>
      <form onSubmit={handleSubmitRegister} className="formAccess-formulary">
        <div className="change-container">
          <div className="change-card row justify-content-center">
            <div className="changep-title">
              <span>Cambio de contraseña</span>
            </div>
            <div className="change-inputs col col-11 my-3">
              <FloatingLabel label="Contraseña actual">
                <Form.Control
                  type="password"
                  id="password_registermb"
                  name="password"
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={valido.password && touched.password}
                  isInvalid={!valido.password && touched.password}
                  placeholder="Contraseña"
                />
                <Form.Control.Feedback type="invalid">
                  Recuerda que debe ser tu contraseña actual
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="change-inputs col col-11 my-3">
              <FloatingLabel label="Contraseña nueva">
                <Form.Control
                  type="password"
                  id="password_registermb"
                  name="newPassword"
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={valido.newPassword && touched.newPassword}
                  isInvalid={!valido.newPassword && touched.newPassword}
                  placeholder="Contraseña"
                />
                <Form.Control.Feedback type="invalid">
                  Contraseña inválida, debe tener al menos 6 caracteres,caracter
                  especial, minusculas, mayusculas y numeros,
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="change-inputs col col-11 my-3">
              <FloatingLabel label="Confirmar contraseña">
                <Form.Control
                  type="password"
                  id="confirmPassword_registermb"
                  name="confirmPassword"
                  value={registerData.confirmPassword}
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={
                    registerData.confirmPassword === registerData.newPassword &&
                    touched.confirmPassword
                  }
                  isInvalid={
                    registerData.confirmPassword !== registerData.newPassword &&
                    touched.confirmPassword
                  }
                  placeholder="Confirmar contraseña"
                />
                <Form.Control.Feedback type="invalid">
                  La contraseña no coincide
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="changepass-handleb formAccess-form-group">
              <button type="submit">Aceptar</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default ChangePassword;
