/** @format */

const URL_SERVER = process.env.REACT_APP_URL_SERVER;

export async function resetPassword(token, password) {
  const response = await fetch(`${URL_SERVER}/users/resetPassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, password }),
  });
  const data = await response.json();
  return data;
}
export async function changePassword(allData) {
  const response = await fetch(`${URL_SERVER}/users/changePassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(allData),
  });
  const data = await response.json();
  return data;
}

export async function requestRefreshPassword(emailUser) {
  const response = await fetch(`${URL_SERVER}/users/forgotPassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: emailUser }),
  });
  const data = await response.json();
  return data;
}

export async function createClientUser(clientUser) {
  const response = await fetch(`${URL_SERVER}/users/createClientUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clientUser),
  });
  const data = await response.json();
  return data;
}

export async function createSellerUser(sellerUser) {
  const response = await fetch(`${URL_SERVER}/users/createSellerUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sellerUser),
  });
  const data = await response.json();
  return data;
}

export async function verifyUserCredentials(userCredentials) {
  const response = await fetch(`${URL_SERVER}/users/verifyCredentials`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await response.json();
  return data;
}

export async function logOut(token) {
  const response = await fetch(`${URL_SERVER}/users/logOut`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
}

export async function refreshToken(token) {
  const response = await fetch(`${URL_SERVER}/users/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
}

export async function logout(token) {
  const response = await fetch(`${URL_SERVER}/users/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
}
