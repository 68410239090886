/** @format */

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "../../styles/modal.css"; // Ruta al archivo CSS
import { notify } from "@services/feedback";

function ConModaldeuda(props) {
  const [deb, setDeb] = useState(1);
  const [debvalid, setDebvalid] = useState(true);
  const handleAcept = () => {
    if (debvalid) {
      props.method(deb);
      props.setShow(false);
    } else {
      notify("Debes seleccionar un numero de pagos");
    }
  };
  const handledebchange = (event) => {
    const { value } = event.target;
    if (value < 1 || value > 3) {
      setDebvalid(false);
    } else {
      setDebvalid(true);
    }
    setDeb(value);
  };
  return (
    <>
      <Modal show={props.show} onHide={() => props.setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modaldeu-text">{props.text}</div>
          <div class="row modaldeu">
            <div class="col-12 col-lg-9 ">
              Selecciona cuantos pagos quieres hacer
            </div>
            <div class=" col-12 col-lg-3 modaldeu-num">
              <Form.Control
                type="number"
                id="inputPassword5"
                value={deb}
                name="deb"
                onChange={handledebchange}
                isInvalid={deb < 1 || deb > 3}
                isValid={deb > 0}
                aria-describedby="passwordHelpBlock"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShow(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAcept}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConModaldeuda;
