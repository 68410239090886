/** @format */

import React, { useState, useEffect, useRef } from "react";

import {
  getExcelReport,
  getFinalBillingsBySeller,
  getFinalBillingsAll,
} from "@services/reportes";
import { verifySeller } from "@services/sellers";
import Cookies from "js-cookie";
import "simple-datatables/dist/style.css";
import { DataTable } from "simple-datatables";
import "@styles/filterTable.css";

const FilterSubs = (props) => {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [ward] = useState(Cookies.get("ward"));
  const myTableRef = useRef(null);
  let dataTable;

  const dataTableOptions = {
    searchable: true,
    fixedHeight: true,
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
    },
  };

  async function fetchService() {
    let response = [];
    const token = Cookies.get("token");
    const seller = await verifySeller(token);
    if (ward === "1") {
      response = await getFinalBillingsBySeller(seller.result.email);
    } else if (ward === "0") {
      response = await getFinalBillingsAll();
    }
    return response || [];
  }

  const handleFormatExcel = async (data) => {
    try {
      const lista = [];

      data.map((item, index) => {
        const tmp = {};
        tmp.nombreVendedor = item.Contract.Seller.name;
        tmp.identificacion = item.identificacion;
        tmp.nombre = item.nombre;
        tmp.correoElectronico = item.correoElectronico;
        tmp.createdAt = item.createdAt.split("T")[0];
        tmp.FiniContrato = item.Contract.createdAt.split("T")[0];
        tmp.noContrato = item.Contract.id;
        tmp.value = item.value;
        tmp.proximoCobro = item.Contract.due_date.split("T")[0];
        lista.push(tmp);
      });

      return lista;
    } catch (error) {
      console.log("Error al formatear los datos", error);
    }
  };

  const handleDownload = async () => {
    getExcelReport(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await handleFormatExcel(await fetchService());
      setData(data);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (dataLoaded && myTableRef.current) {
      if (dataTable) {
        dataTable.destroy();
      }
      dataTable = new DataTable(myTableRef.current, dataTableOptions);
      props.domMethod();
    }
  }, [dataLoaded]);

  return (
    <>
      <section className="dashCust-container">
        <section className="dasCusto-card rounded">
          <div className="seller-content-header">
            <div className="seller-title">
              <span>Transacciones</span>
            </div>
            <div className="pdf-down" onClick={handleDownload}>
              <i className="bi bi-filetype-xlsx"></i>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr>
                  {ward === "1" && (
                    <>
                      <th>Identificacion</th>
                      <th>Nombres y apellidos</th>
                      <th>No. Contrato</th>
                      <th>Fecha de transaccion</th>
                      <th>F. Inicio de contrato</th>
                      <th>Valor</th>
                      <th>Proximo cobro</th>
                      <th>Correo</th>
                    </>
                  )}
                  {ward === "0" && (
                    <>
                      <th>Vendedor</th>
                      <th>Identificacion</th>
                      <th>Nombres y apellidos</th>
                      <th>No. Contrato</th>
                      <th>Fecha de transaccion</th>
                      <th>F. Inicio de contrato</th>
                      <th>Valor</th>
                      <th>Proximo cobro</th>
                      <th>Correo</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  ward === "1" &&
                  data.map((item, index) => (
                    <tr key={`a${index}`}>
                      <td key={`b${index}`}>{item.identificacion}</td>
                      <td key={`c${index}`}>{item.nombre}</td>
                      <td key={`d${index}`}>{item.noContrato}</td>
                      <td key={`e${index}`}>{item.createdAt}</td>
                      <td key={`f${index}`}>{item.FiniContrato}</td>
                      <td key={`g${index}`}>{item.value}</td>
                      <td key={`h${index}`}>{item.proximoCobro}</td>
                      <td key={`i${index}`}>{item.correoElectronico}</td>
                    </tr>
                  ))}
                {data.length > 0 &&
                  ward === "0" &&
                  data.map((item, index) => (
                    <tr key={`j${index}`}>
                      <td key={`k${index}`}>{item.nombreVendedor}</td>
                      <td key={`l${index}`}>{item.identificacion}</td>
                      <td key={`m${index}`}>{item.nombre}</td>
                      <td key={`n${index}`}>{item.noContrato}</td>
                      <td key={`o${index}`}>{item.createdAt}</td>
                      <td key={`p${index}`}>{item.FiniContrato}</td>
                      <td key={`q${index}`}>{item.value}</td>
                      <td key={`r${index}`}>{item.proximoCobro}</td>
                      <td key={`s${index}`}>{item.correoElectronico}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </>
  );
};

export default FilterSubs;
