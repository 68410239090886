/** @format */

const URL_SERVER = process.env.REACT_APP_URL_SERVER;

export async function getAllSellers() {
  const response = await fetch(`${URL_SERVER}/seller/getAllSellers`);
  const data = await response.json();
  return data;
}

export async function createSeller(seller) {
  let data = "";
  try {
    const response = await fetch(`${URL_SERVER}/admin/createSeller`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(seller),
    });
    data = await response.json();
  } catch (error) {
    data = null;
  }
  return data;
}

export async function verifySeller(token) {
  try {
    const response = await fetch(`${URL_SERVER}/seller/verifySeller`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {}
}
