/** @format */

import React, { useState, useEffect, useRef } from "react";
import Decimal from "decimal.js";

import {
  // getContractsBySeller,
  getContracts,
  updateDebt,
  inactiveContract,
} from "@services/contracts";

import {
  getFinalContractsBySeller,
  getFinalContractsAll,
} from "@services/reportes";
// import notify from "react-notify-toast";

import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";
import { verifySeller } from "../../services/sellers";
import ConfirmationModal from "../modals/confirmationModal";
import ConModaldeuda from "../modals/conModaldeuda";
import { notify } from "@services/feedback";
import { getPaymentRequest } from "@services/payments";
import { getTableReport, getExcelReport } from "@services/reportes";
import "simple-datatables/dist/style.css";
import { DataTable } from "simple-datatables";
import "@styles/filterTable.css";

const FilterSubs = (props) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dataTmp, setDataTmp] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [ward] = useState(Cookies.get("ward"));
  const [page] = useState(1);
  const myTableRef = useRef(null);
  let dataTable;

  const dataTableOptions = {
    searchable: true,
    fixedHeight: true,
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
    },
  };

  async function fetchService() {
    let response = null;
    const token = Cookies.get("token");
    const seller = await verifySeller(token);
    if (ward === "1") {
      response = await getFinalContractsBySeller(seller.result.email);
    } else if (ward === "0") {
      response = await getFinalContractsAll();
    }
    return response || [];
  }

  const handleFormatExcel = async (data) => {
    // Establecer la precisión global para Decimal
    Decimal.set({ precision: 20 });
    try {
      const lista = [];
      data.forEach((c) => {
        c.Contracts.forEach((d) => {
          const iva = new Decimal(0.12);
          const one = new Decimal(1);
          const year = new Decimal(12);
          const totalTmp = new Decimal(1.12);

          const productoPrecio = new Decimal(d.Producto.precio);
          const anexoPrecio = new Decimal(d.Anexo.precio);
          const totalPrecio = productoPrecio.plus(anexoPrecio);
          const subtotalAnual = new Decimal(
            new Decimal(totalPrecio.times(one).toFixed(2))
              .times(year)
              .toFixed(2)
          );
          const ivaAnual = new Decimal(
            new Decimal(totalPrecio.times(iva).toFixed(2))
              .times(year)
              .toFixed(2)
          );
          const valorNetoAnual = new Decimal(
            new Decimal(totalPrecio.times(totalTmp).toFixed(2))
              .times(year)
              .toFixed(2)
          );
          const subtotalCuotaMensual = totalPrecio.times(one);
          const ivaCuotaMensual = totalPrecio.times(iva);
          const totalCuotaMensual = totalPrecio.times(totalTmp);
          const debtDecimal = new Decimal(d.debt);
          const valorAdeudado = new Decimal(
            new Decimal(debtDecimal.times(totalPrecio).toFixed(2))
              .times(totalTmp)
              .toFixed(2)
          );

          const totalDelIva = totalPrecio.times(iva);

          const tmp = {
            nombre:
              c.principalContact === null
                ? c.name
                : `Contacto: ${c.principalContact}`,
            sellerName: d.Seller.name,
            identificacion: c.ci,
            contactoPrincipal:
              c.principalContact === null ? "" : c.principalContact,
            direccion: c.address,
            correo: c.email,
            celular: c.cellphone,
            noContrato: d.id,
            estadoFirma: d.sign,
            estadoContrato: d.is_active ? "Activo" : "Inactivo",
            plan: `${d.Producto.nombre}`,
            tipoPlan: `${d.Producto.variante} ${d.Anexo.nombre}`,
            fechaInicioContrato: d.createdAt.split("T")[0],
            subtotalAnual: subtotalAnual.toFixed(2),
            ivaAnual: ivaAnual.toFixed(2),
            valorNetoAnual: valorNetoAnual.toFixed(2),
            subtotalCuotaMensual: subtotalCuotaMensual.toFixed(2),
            ivaCuotaMensual: ivaCuotaMensual.toFixed(2),
            totalCuotaMensual: totalCuotaMensual.toFixed(2),
            cuotasPagadas: calcularCuotasPagadas(d),
            cuotasPendientes: d.debt,
            contract: d,
            valorAdeudado: valorAdeudado.toFixed(2),
            proximoCobro: d.due_date.split("T")[0],
          };
          lista.push(tmp);
        });
      });
      return lista;
    } catch (error) {
      throw error; // Agregado para propagar el error
    }
  };

  const handleDownload = async () => {
    getExcelReport(data);
  };
  async function updateContractDebt(contract) {
    setShow(true);
    setDataTmp(contract);
  }
  async function nextUpdateContractDebt(count) {
    const response = await updateDebt(dataTmp.id, count);
    if (response.success) {
      window.location.reload();
    } else {
      notify(response.message);
    }
  }
  async function updateHandleActivatePR(email, id) {
    setShow1(true);
    setDataTmp({ email, id });
  }

  async function handleActivatePR(count) {
    const respone = await getPaymentRequest(
      dataTmp.email,
      dataTmp.id,
      "Pago unico",
      count
    );
    if (respone.success) {
      window.location.href = respone.url;
    } else {
      // notify("Error al obtener el contrato");
    }
  }
  async function updatehHndleInactivarContract(contract) {
    setShow2(true);
    setDataTmp(contract);
  }
  async function handleInactivarContract() {
    const response = await inactiveContract(dataTmp);
    if (response.success) {
      window.location.reload();
    } else {
      // notify("Error al inactivar el contrato");
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const data = await handleFormatExcel(await fetchService());
      setData(data);
      setContracts(data);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (dataLoaded && myTableRef.current) {
      if (dataTable) {
        dataTable.destroy();
      }
      dataTable = new DataTable(myTableRef.current, dataTableOptions);
      props.domMethod();
    }
  }, [dataLoaded]);

  const calcularCuotasPagadas = (contract) => {
    // Fecha de inicio del contrato (ejemplo)
    const fechaInicioContrato = new Date(contract.createdAt);
    // const fechaInicioContrato = new Date("2023-02-12T17:26:49.000Z");

    // Fecha actual
    const fechaActual = new Date().getTime();

    // Cantidad de cuotas de deuda del contrato (ejemplo)
    const cuotasDeudaContrato = contract.debt;

    // Cálculo de la cantidad de cuotas pagadas
    const tiempoTranscurridoMs = fechaActual - fechaInicioContrato.getTime();
    const tiempoTranscurridoDias = Math.floor(
      tiempoTranscurridoMs / (1000 * 60 * 60 * 24)
    );
    const cuotasPagadas = Math.max(
      0,
      Math.floor(tiempoTranscurridoDias / 30) - cuotasDeudaContrato
    );
    return cuotasPagadas;
  };

  return (
    <>
      <section className="dashCust-container">
        <section className="dasCusto-card rounded">
          <ConModaldeuda
            show={show}
            setShow={setShow}
            text={"¿Está seguro que desea reducir la deuda?"}
            method={nextUpdateContractDebt}
          />
          <ConModaldeuda
            show={show1}
            setShow={setShow1}
            text={"¿Estás seguro que quieres realizar el pago?"}
            method={handleActivatePR}
          />
          <ConfirmationModal
            show={show2}
            setShow={setShow2}
            text={"¿Estás seguro que quieres inactivar este contrato?"}
            method={handleInactivarContract}
          />
          {/* <button onClick={() => setShow(true)}>click me</button> */}
          <div className="seller-content-header">
            <div className="seller-title">
              <span>Contratos</span>
            </div>
            <div className="pdf-down" onClick={handleDownload}>
              <i className="bi bi-filetype-xlsx"></i>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table ">
              <thead>
                {ward === "1" && (
                  <>
                    <tr>
                      <th>#</th>
                      <th>Identificacion</th>
                      <th>Nombres y apellidos</th>
                      <th>Contacto</th>
                      <th>Direccion</th>
                      <th>Correo electronico</th>
                      <th>Celular</th>
                      <th>No. Contrato</th>
                      <th>Estado de la firma</th>
                      <th>Estado del contrato</th>
                      <th>Plan</th>
                      <th>Tipo de Plan</th>
                      <th>F. Inicio de contrato</th>
                      <th>Subtotal Anual</th>
                      <th>IVA Anual</th>
                      <th>Valor Neto Anual</th>
                      <th>Subtotal Cuota Mensual</th>
                      <th>IVA Cuota Mensual</th>
                      <th>Total Cuota Mensual</th>
                      <th>Cuotas pendientes</th>
                      <th>Valor Adeudado</th>
                      <th>Proximo Cobro</th>
                    </tr>
                  </>
                )}
                {ward === "0" && (
                  <>
                    <tr>
                      <th>#</th>
                      <th>Vendedor</th>
                      <th>Identificacion</th>
                      <th>Nombres y apellidos</th>
                      <th>Contacto</th>
                      <th>Direccion</th>
                      <th>Correo electronico</th>
                      <th>Celular</th>
                      <th>No. Contrato</th>
                      <th>Estado de la firma</th>
                      <th>Estado del contrato</th>
                      <th>Plan</th>
                      <th>Tipo de Plan</th>
                      <th>F. Inicio de contrato</th>
                      <th>Subtotal Anual</th>
                      <th>IVA Anual</th>
                      <th>Valor Neto Anual</th>
                      <th>Subtotal Cuota Mensual</th>
                      <th>IVA Cuota Mensual</th>
                      <th>Total Cuota Mensual</th>
                      <th>Cuotas pendientes</th>
                      <th>Valor Adeudado</th>
                      <th>Proximo Cobro</th>
                      <th>Reducir deuda</th>
                      <th>Pagar deuda</th>
                      <th>Inactivar contrato</th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody>
                {contracts.length > 0 &&
                  ward === "1" &&
                  contracts.map((c, index) => {
                    return (
                      <tr key={`1${index}`}>
                        <td key={`b${index}`}>{index + 1}</td>
                        <td key={`c${index}`}>{c.identificacion}</td>
                        <td key={`d${index}`}>{c.nombre}</td>
                        <td key={`2${index}`}>{c.contactoPrincipal}</td>
                        <td key={`f${index}`}>{c.direccion}</td>
                        <td key={`g${index}`}>{c.correo}</td>
                        <td key={`h${index}`}>{c.celular}</td>
                        <td key={`i${index}`}>{c.noContrato}</td>
                        <td key={`j${index}`}>{c.estadoFirma}</td>
                        <td key={`k${index}`}>{c.estadoContrato}</td>
                        <td key={`l${index}`}>{c.plan}</td>
                        <td key={`m${index}`}>{c.tipoPlan}</td>
                        <td key={`n${index}`}>{c.fechaInicioContrato}</td>
                        <td key={`o${index}`}>{c.subtotalAnual}</td>
                        <td key={`p${index}`}>{c.ivaAnual}</td>
                        <td key={`q${index}`}>{c.valorNetoAnual}</td>
                        <td key={`r${index}`}>{c.subtotalCuotaMensual}</td>
                        <td key={`s${index}`}>{c.ivaCuotaMensual}</td>
                        <td key={`t${index}`}>{c.totalCuotaMensual}</td>
                        <td key={`u${index}`}>{c.cuotasPendientes}</td>
                        <td key={`v${index}`}>{c.valorAdeudado}</td>
                        <td key={`w${index}`}>{c.proximoCobro}</td>
                      </tr>
                    );
                  })}
                {contracts.length > 0 &&
                  ward === "0" &&
                  contracts.map((c, index) => {
                    return (
                      <tr key={`x${index}`}>
                        <td key={`aa${index}`}>{index + 1}</td>
                        <td key={`ab${index}`}>{c.sellerName}</td>
                        <td key={`ac${index}`}>{c.identificacion}</td>
                        <td key={`ad${index}`}>{c.nombre}</td>
                        <td key={`ae${index}`}>{c.contactoPrincipal}</td>
                        <td key={`aaa${index}`}>{c.direccion}</td>
                        <td key={`abb${index}`}>{c.correo}</td>
                        <td key={`aabu${index}`}>{c.celular}</td>
                        <td key={`aab${index}`}>{c.noContrato}</td>
                        <td key={`aae${index}`}>{c.estadoFirma}</td>
                        <td key={`aaf${index}`}>{c.estadoContrato}</td>
                        <td key={`aag${index}`}>{c.plan}</td>
                        <td key={`aah${index}`}>{c.tipoPlan}</td>
                        <td key={`aai${index}`}>{c.fechaInicioContrato}</td>
                        <td key={`aaj${index}`}>{c.subtotalAnual}</td>
                        <td key={`aak${index}`}>{c.ivaAnual}</td>
                        <td key={`aal${index}`}>{c.valorNetoAnual}</td>
                        <td key={`aam${index}`}>{c.subtotalCuotaMensual}</td>
                        <td key={`aan${index}`}>{c.ivaCuotaMensual}</td>
                        <td key={`aao${index}`}>{c.totalCuotaMensual}</td>
                        <td key={`aap${index}`}>{c.cuotasPendientes}</td>
                        <td key={`aaq${index}`}>{c.valorAdeudado}</td>
                        <td key={`aar${index}`}>{c.proximoCobro}</td>
                        <td key={`aas${index}`} className="text-center">
                          <button
                            className="button2"
                            disabled={c.cuotasPendientes <= 0}
                            onClick={() => updateContractDebt(c.contract)}
                            key={`k${index}`}
                          >
                            Reducir
                          </button>
                        </td>
                        <td key={`ai${index}`} className="text-center">
                          <button
                            className="button2"
                            disabled={c.cuotasPendientes <= 0}
                            onClick={() =>
                              updateHandleActivatePR(c.correo, c.noContrato)
                            }
                            key={`akw${index}`}
                          >
                            Pagar
                          </button>
                        </td>
                        <td key={`aiq${index}`} className="text-center">
                          <button
                            className="button2"
                            disabled={c.estadoContrato !== "Activo"}
                            onClick={() =>
                              updatehHndleInactivarContract(c.noContrato)
                            }
                            key={`akd${index}`}
                          >
                            Inactivar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </>
  );
};

export default FilterSubs;
