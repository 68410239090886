/** @format */

import React, { useState, useEffect } from "react";
import { verifyUserCredentials, refreshToken } from "@services/users";
import { useNavigate } from "react-router-dom";
import Roles from "@config/roles";
import Cookies from "js-cookie";
import "@styles/login.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { createSellerUser } from "@services/users";
import "../styles/formAccess.css"; // Ruta al archivo CSS
import { notify } from "@services/feedback";

const Login = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const [formData, setFormData] = useState({
    email_login: "",
    password_login: "",
  });
  const [subRegister, setSubRegister] = useState(false);
  const [subLogin, setSubLogin] = useState(false);
  function handleClick(text) {
    notify(text);
  }
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
  });
  const regex = {
    email_login: /^[\s\S]*$/,
    password_login: /^[\s\S]*$/,
    name: /^.{1,30}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    password:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    confirmPassword: /^[\s\S]*$/,
    token: /^\d{6}$/,
  };
  const [valido, setValium] = useState({
    email_login: false,
    password_login: false,
    name: false,
    email: false,
    password: false,
    confirmPassword: false,
    token: false,
  });
  const [touched, setTouched] = useState({
    email_login: false,
    password_login: false,
    name: false,
    email: false,
    password: false,
    confirmPassword: false,
    token: false,
  });
  const validarInput = (nombreCampo, valor) => {
    if (regex[nombreCampo].test(valor)) {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: true }));
    } else {
      setValium((prevValido) => ({ ...prevValido, [nombreCampo]: false }));
    }
  };
  const manejarBlur = (event) => {
    const { name, value } = event.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validarInput(name, value);
  };
  useEffect(() => {
    if (typeof token !== "undefined" && token !== "undefined") {
      refresh(token);
    }
  });
  const handleInputChangeRegister = (event) => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
    validarInput(event.target.name, event.target.value);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    validarInput(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };
  const handleSubmitRegister = (event) => {
    event.preventDefault();
    const passwordMatch =
      registerData.password === registerData.confirmPassword;
    const { email_login, password_login, ...restOfFields } = valido;
    const formIsValid = Object.values(restOfFields).every((value) => value);
    if (passwordMatch && formIsValid) {
      setSubRegister(false);
      createUser();
    } else {
      setSubRegister(true);
    }
  };

  async function login() {
    const response = await verifyUserCredentials({
      email: formData.email_login,
      password: formData.password_login,
    });
    if (response.status === 200) {
      setSubLogin(false);

      Cookies.set("token", response.token);
      Cookies.set("name", response.name);
      Cookies.set("ward", response.role);
      let rolePath = parseInt(localStorage.getItem("confirmRole"));
      if (response.role === Roles.SELLER && rolePath === response.role) {
        navigate("/dashboard/contract", { replace: true });
        handleClick(`Bienvenido Vendedor ${response.name}`);
      } else if (response.role === Roles.MASTER && rolePath === response.role) {
        navigate("/dashboard/contract", { replace: true });
        handleClick(`Bienvenido Administrador ${response.name}`);
      } else {
        Cookies.set("token", "");
        handleClick(
          `Debes de ingresar como ${
            response.role === 0 ? "administrador" : "vendedor"
          }`,
          "toast-header-danger"
        );
      }
    } else {
      handleClick("Correo o contraseña incorrectos", "toast-header-danger");
      setSubLogin(true);
    }
  }
  async function createUser() {
    const response = await createSellerUser(registerData);
    if (response.status === 200) {
      setRegisterData({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        token: "",
      });
      handleClick("Usuario creado correctamente");
    } else {
      handleClick("Verifica tus datos");
    }
  }
  async function refresh(token) {
    const response = await refreshToken(token);
    if (response.status === 200) {
      Cookies.set("token", response.token);

      if (response.role === Roles.SELLER) {
        navigate("/dashboard/contract", { replace: true });
      } else if (response.role === Roles.MASTER) {
        navigate("/dashboard/contract", { replace: true });
      }
    } else {
      Cookies.remove("token");
    }
  }

  const handleForgotPassword = () => {
    navigate("/refresh-password", { replace: false });
  };

  return (
    <>
      <div className="login-container my-5">
        <div className="login-content d-lg-none">
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="home" title="Acceder">
              <form onSubmit={handleSubmit} className="login-access-form">
                <div className="row justify-content-center">
                  <div className="col col-11 my-3">
                    <FloatingLabel label="Correo">
                      <Form.Control
                        type="email"
                        id="emailmb"
                        name="email_login"
                        value={formData.email_login}
                        onChange={handleInputChange}
                        onBlur={manejarBlur}
                        placeholder="Correo"
                        isValid={valido.email_login && touched.email_login}
                        isInvalid={!valido.email_login && touched.email_login}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col col-11 mb-3">
                    <FloatingLabel label="Contraseña">
                      <Form.Control
                        type="password"
                        id="passwordmb"
                        name="password_login"
                        value={formData.password_login}
                        onChange={handleInputChange}
                        placeholder="Contraseña"
                        onBlur={manejarBlur}
                        isValid={
                          valido.password_login && touched.password_login
                        }
                        isInvalid={
                          !valido.password_login && touched.password_login
                        }
                        required
                      />
                    </FloatingLabel>
                  </div>
                  {/* <div className="col col-11">
                    <div key={`default-radio`} className="mb-3">
                      <Form.Check
                        type="checkbox"
                        name="recuerdame"
                        onChange={handleRecuerdame}
                        value={recuerdame}
                        id={`default-radio`}
                        label={`Recuérdame`}
                      />
                    </div>
                  </div> */}
                  <div className="col col-11 formAccess-form-group mb-4">
                    <button type="submit">Acceder</button>
                    <div
                      style={{ marginTop: "1rem" }}
                      onClick={handleForgotPassword}
                    >
                      <span className="formAccess-forgot-password">
                        ¿Olvidaste la contraseña?
                      </span>
                    </div>
                    <div className="feedback-button-container">
                      {subRegister && (
                        <span className="feedback-button">
                          Registro inválido
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </Tab>
            {/* Registersee */}
            <Tab eventKey="profile" title="Registrarse">
              <form
                onSubmit={handleSubmitRegister}
                className="formAccess-formulary"
              >
                <div className="row justify-content-center">
                  <div className="col col-11 my-3">
                    <FloatingLabel label="Nombre">
                      <Form.Control
                        type="text"
                        id="name_registermb"
                        name="name"
                        value={registerData.name}
                        onChange={handleInputChangeRegister}
                        required
                        onBlur={manejarBlur}
                        isValid={valido.name && touched.name}
                        isInvalid={!valido.name && touched.name}
                        placeholder="Nombre"
                      />
                      <Form.Control.Feedback type="invalid">
                        Nombre inválido
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </div>
                  <div className="col col-11 mb-3">
                    <FloatingLabel label="Correo">
                      <Form.Control
                        type="email"
                        id="email_registermb"
                        name="email"
                        value={registerData.email}
                        onChange={handleInputChangeRegister}
                        required
                        onBlur={manejarBlur}
                        isValid={valido.email && touched.email}
                        isInvalid={!valido.email && touched.email}
                        placeholder="Correo"
                      />
                      <Form.Control.Feedback type="invalid">
                        Correo inválido
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </div>

                  <div className="col col-11 mb-3">
                    <FloatingLabel label="Contraseña">
                      <Form.Control
                        type="password"
                        id="password_registermb"
                        name="password"
                        value={registerData.password}
                        onChange={handleInputChangeRegister}
                        required
                        onBlur={manejarBlur}
                        isValid={valido.password && touched.password}
                        isInvalid={!valido.password && touched.password}
                        placeholder="Contraseña"
                      />
                      <Form.Control.Feedback type="invalid">
                        Contraseña inválida, debe tener al menos 6
                        caracteres,caracter especial, minusculas, mayusculas y
                        numeros,
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </div>
                  <div className="col col-11 mb-3">
                    <FloatingLabel label="Confirmar contraseña">
                      <Form.Control
                        type="password"
                        id="confirmPassword_registermb"
                        name="confirmPassword"
                        value={registerData.confirmPassword}
                        onChange={handleInputChangeRegister}
                        required
                        onBlur={manejarBlur}
                        isValid={
                          registerData.confirmPassword ===
                            registerData.password &&
                          registerData.password !== "" &&
                          touched.confirmPassword
                        }
                        isInvalid={
                          registerData.confirmPassword !==
                            registerData.password && touched.confirmPassword
                        }
                        placeholder="Confirmar contraseña"
                      />
                      <Form.Control.Feedback type="invalid">
                        La contraseña no coincide
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </div>
                  <div className="col col-11 mb-3">
                    <FloatingLabel label="Token">
                      <Form.Control
                        type="text"
                        id="token_registermb"
                        name="token"
                        value={registerData.token}
                        onChange={handleInputChangeRegister}
                        required
                        onBlur={manejarBlur}
                        isValid={valido.token && touched.token}
                        isInvalid={!valido.token && touched.token}
                        placeholder="Token"
                      />
                      <Form.Control.Feedback type="invalid">
                        valor inválido
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </div>

                  <div className=" col col-11 formAccess-form-group">
                    <div className="formAccess-text-politic">
                      <p>
                        Se enviará un enlace a tu dirección de correo
                        electrónico para establecer una nueva contraseña.
                      </p>
                      <p className="formAccess-politics">
                        Tus datos personales se utilizarán para procesar tu
                        pedido, mejorar tu experiencia en esta web, gestionar el
                        acceso a tu cuenta y otros propósitos descritos en
                        nuestra
                        <a
                          href="https://safewaylink.com/?page_id=3"
                          className="woocommerce-privacy-policy-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          política de privacidad
                        </a>
                        .
                      </p>
                    </div>
                    <div className="formAccess-form-group">
                      <button type="submit">Registrarse</button>
                    </div>
                    <div className="feedback-button-container">
                      {subRegister && (
                        <span className="feedback-button">
                          Registro inválido
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div className="formAccess-form-container d-none d-lg-flex mt-5">
        <div className="formAccess-form-section">
          <h2 className="pcolor">Acceder</h2>
          <form onSubmit={handleSubmit} className="formAccess-formulary my-3">
            <FloatingLabel label="Correo" className="mb-3">
              <Form.Control
                type="email"
                id="email"
                name="email_login"
                value={formData.email_login}
                onChange={handleInputChange}
                required
                onBlur={manejarBlur}
                // isValid={valido.email_login && touched.email_login}
                isInvalid={subLogin && touched.email_login}
                placeholder="Correo"
              />
            </FloatingLabel>

            <FloatingLabel label="Contraseña" className="mb-3">
              <Form.Control
                type="password"
                id="password"
                name="password_login"
                value={formData.password_login}
                onChange={handleInputChange}
                required
                onBlur={manejarBlur}
                // isValid={valido.password_login && touched.password_login}
                isInvalid={subLogin && touched.password_login}
                placeholder="Contraseña"
              />
            </FloatingLabel>
            <div className="formAccess-form-group">
              <label>
                <input
                  className="formAccess-check"
                  type="checkbox"
                  name="rememberMe"
                  id="check"
                />{" "}
                Recuérdame
              </label>
            </div>
            <div className="formAccess-form-group ">
              <button>Acceder</button>
              <div className="feedback-button-container">
                {subLogin && (
                  <span className="feedback-button">
                    Correo o contraseña incorrectos
                  </span>
                )}
              </div>
              <div style={{ marginTop: "1rem" }} onClick={handleForgotPassword}>
                <span className="formAccess-forgot-password">
                  ¿Olvidaste la contraseña?
                </span>
              </div>
            </div>
          </form>
        </div>
        <div className="formAccess-form-divider"></div>
        {/* registersee */}
        <div className="formAccess-form-section">
          <h2 className="pcolor">Registrarse</h2>
          <form
            onSubmit={handleSubmitRegister}
            className="formAccess-formulary"
          >
            <div className="col col-11 my-3">
              <FloatingLabel label="Nombre">
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  value={registerData.name}
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={valido.name && touched.name}
                  isInvalid={!valido.name && touched.name}
                  placeholder="Nombre"
                />
                <Form.Control.Feedback type="invalid">
                  Nombre inválido
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="col col-11 mb-3">
              <FloatingLabel label="Correo">
                <Form.Control
                  type="email"
                  id="email_register"
                  name="email"
                  value={registerData.email}
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={valido.email && touched.email}
                  isInvalid={!valido.email && touched.email}
                  placeholder="Correo"
                />{" "}
                <Form.Control.Feedback type="invalid">
                  Correo inválido
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>

            <div className="col col-11 mb-3">
              <FloatingLabel label="Contraseña">
                <Form.Control
                  type="password"
                  id="password_register"
                  name="password"
                  value={registerData.password}
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={valido.password && touched.password}
                  isInvalid={!valido.password && touched.password}
                  placeholder="Contraseña"
                />{" "}
                <Form.Control.Feedback type="invalid">
                  Contraseña inválida, debe tener al menos 6 caracteres,caracter
                  especial, minusculas, mayusculas y numeros,
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="col col-11 mb-3">
              <FloatingLabel label="Confirmar contraseña">
                <Form.Control
                  type="password"
                  id="confirmPassword_register"
                  name="confirmPassword"
                  value={registerData.confirmPassword}
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={
                    registerData.confirmPassword === registerData.password &&
                    registerData.password !== "" &&
                    touched.confirmPassword
                  }
                  isInvalid={
                    registerData.confirmPassword !== registerData.password &&
                    touched.confirmPassword
                  }
                  placeholder="Confirmar contraseña"
                />{" "}
                <Form.Control.Feedback type="invalid">
                  La contraseña no coincide
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="col col-11 mb-3">
              <FloatingLabel label="Token">
                <Form.Control
                  type="text"
                  id="token_register"
                  name="token"
                  value={registerData.token}
                  onChange={handleInputChangeRegister}
                  required
                  onBlur={manejarBlur}
                  isValid={valido.token && touched.token}
                  isInvalid={!valido.token && touched.token}
                  placeholder="Token"
                />{" "}
                <Form.Control.Feedback type="invalid">
                  valor inválido
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="formAccess-text-politic">
              <p>
                Se enviará un enlace a tu dirección de correo electrónico para
                establecer una nueva contraseña.
              </p>
              <p className="formAccess-politics">
                Tus datos personales se utilizarán para procesar tu pedido,
                mejorar tu experiencia en esta web, gestionar el acceso a tu
                cuenta y otros propósitos descritos en nuestra{" "}
                <span
                  className="login-politics"
                  onClick={() => navigate("/legal", { replace: true })}
                >
                  política de privacidad
                </span>
                .
              </p>
            </div>
            <div className="formAccess-form-group">
              <button>Registrarse</button>
            </div>
            <div className="feedback-button-container">
              {subRegister && (
                <span className="feedback-button">Registro inválido</span>
              )}
            </div>
          </form>
        </div>
      </div>
      {/* <Bubbles /> */}
    </>
  );
};

export default Login;
