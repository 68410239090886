/** @format */

import { Route, Routes, Navigate } from "react-router-dom";
import Home from "@components/home";
import Login from "@components/login";
import Register from "@components/register";
import Layout from "@components/layout";
import RequireAuth from "@guards/requireAuth";
import Unauthorized from "@components/unauthorized";
import RefreshPassword from "@components/refreshPassword";
import Tienda from "@components/tienda";
import ProductsDetail from "@components/productsDetail";
import CarritoView from "@components/carrito/carritoView";
import { useState } from "react";
import Legal from "@components/legal";
import Dashboard from "../components/dashboard/dashboard";
import DasCustomers from "@components/dashboard/dasCustomers";
import DashContract from "../components/dashboard/dashContract";
import ResetPassword from "../components/resetPassword";
import DashConfig from "../components/dashboard/dashConfig";
import Error from "../components/Error";
import FilterClient from "../components/dashboard/filterClient";
import FilterMoney from "../components/dashboard/filterMoney";
import FilterTransation from "../components/dashboard/filterTransition";
import FilterSubs from "../components/dashboard/filterSubs";
import ChangePassword from "../components/changePassword";
import DashSeller from "../components/dashboard/dashSeller";
function SwRoutes() {
  const [carrito, setCarrito] = useState([]);
  let carritoState = {
    carrito: carrito,
    setCarrito: setCarrito,
  };
  // crea una funcion
  async function handleTable() {
    var elementos = document.getElementsByClassName("datatable-info");
    for (var i = 0; i < elementos.length; i++) {
      elementos[i].textContent = "";
    }
    var elementos2 = document.getElementsByClassName("datatable-dropdown");
    for (var e = 0; e < elementos2.length; e++) {
      var html = elementos2[e].innerHTML;

      var nuevoHtml = html.replace(" entries per page", "");

      elementos2[e].innerHTML = nuevoHtml;
    }
    var inputs = document.getElementsByClassName("datatable-input");

    for (var o = 0; o < inputs.length; o++) {
      inputs[o].placeholder = "Buscar";
    }
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/register" element={<Register />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/refresh-password" element={<RefreshPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/planes" element={<Tienda />} />

          <Route path="/planes/:planType" element={<ProductsDetail />} />
          <Route path="/legal" element={<Legal carrito={carritoState} />} />
          <Route path="/error" element={<Error />} />
          <Route element={<RequireAuth allowRoles={[0, 1]} />}>
            <Route
              path="/carrito/:pageConf?/:errorConf?"
              element={<CarritoView carrito={carritoState} />}
            />
          </Route>
        </Route>
        <Route element={<RequireAuth allowRoles={[0, 1]} />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route
              path="/dashboard/customer"
              element={<DasCustomers domMethod={handleTable} />}
            />
            <Route
              path="/dashboard/contract"
              element={<DashContract domMethod={handleTable} />}
            />
            <Route path="/dashboard/config" element={<DashConfig />} />
            <Route path="/dashboard/changePss" element={<ChangePassword />} />
            <Route
              path="/dashboard/reportes/seller"
              element={<DashSeller domMethod={handleTable} />}
            />
            <Route
              path="/dashboard/reportes/clientes"
              element={<FilterClient domMethod={handleTable} />}
            />
            <Route
              path="/dashboard/reportes/transacciones"
              element={<FilterTransation domMethod={handleTable} />}
            />
            <Route
              path="/dashboard/reportes/pagos"
              element={<FilterMoney domMethod={handleTable} />}
            />
            <Route
              path="/dashboard/reportes/suscriptores"
              element={<FilterSubs domMethod={handleTable} />}
            />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </>
  );
}

export default SwRoutes;
