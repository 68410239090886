/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
  getContracts,
  updateDebt,
  getContractsBySeller,
} from "@services/contracts";
import {
  getTableReport,
  getExcelReport,
  getBillingsBySeller,
  getFinalSellersAll,
} from "@services/reportes";
import { verifySeller } from "@services/sellers";
import Cookies from "js-cookie";
import Table from "react-bootstrap/Table";
import "simple-datatables/dist/style.css";
import { DataTable } from "simple-datatables";
import "@styles/filterTable.css";

const DashSeller = (props) => {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [ward] = useState(Cookies.get("ward"));
  const [page] = useState(1);
  const myTableRef = useRef(null);
  let dataTable;

  const dataTableOptions = {
    searchable: true,
    fixedHeight: true,
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
    },
  };

  async function fetchService() {
    let response = [];
    const token = Cookies.get("token");
    const seller = await verifySeller(token);
    if (ward === "1") {
      response = await getBillingsBySeller(seller.result.email);
    } else if (ward === "0") {
      response = await getFinalSellersAll();
    }
    return response || [];
  }

  const handleDownload = async () => {
    getExcelReport(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchService();
      setData(data);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (dataLoaded && myTableRef.current) {
      if (dataTable) {
        dataTable.destroy();
      }
      dataTable = new DataTable(myTableRef.current, dataTableOptions);
      props.domMethod();
    }
  }, [dataLoaded]);

  return (
    <>
      <section className="dashCust-container">
        <section className="dasCusto-card rounded">
          <div className="seller-content-header">
            <div className="seller-title">
              <span>Vendedores</span>
            </div>
            <div className="pdf-down" onClick={handleDownload}>
              <i className="bi bi-filetype-xlsx"></i>{" "}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr>
                  {ward === "0" && (
                    <>
                      <th>Nombres y apellidos del Vendedor</th>
                      <th>Correo</th>
                      <th>Fecha de Registro</th>
                      <th>Contratos Activos</th>
                      <th>Contratos Inactivos</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  ward === "0" &&
                  data.map((item, index) => (
                    <tr key={`i${index}`}>
                      <td key={`k${index}`}>{item.name}</td>
                      <td key={`l${index}`}>{item.email}</td>
                      <td key={`m${index}`}>{item.createdAt.split("T")[0]}</td>
                      <td key={`j${index}`}>{item.activeContractsCount}</td>
                      <td key={`g${index}`}>{item.inactiveContractsCount}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </>
  );
};

export default DashSeller;
